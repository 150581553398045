/*!
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       

*/
/* Kendo base CSS */
.fake {
  color: red;
}
.k-common-test-class {
  opacity: 0;
}
.k-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}
.k-floatwrap:after,
.k-slider-items:after,
.k-grid-toolbar:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.k-floatwrap,
.k-slider-items,
.k-grid-toolbar {
  display: inline-block;
}
.k-floatwrap,
.k-slider-items,
.k-grid-toolbar {
  display: block;
}
/* main gradient */
.k-block,
.k-button,
.k-header,
.k-grid-header,
.k-toolbar,
.k-grouping-header,
.k-tooltip,
.k-pager-wrap,
.k-tabstrip-items .k-item,
.k-link.k-state-hover,
.k-textbox,
.k-textbox:hover,
.k-autocomplete,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap,
.k-autocomplete.k-state-hover,
.k-dropdown-wrap.k-state-hover,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover,
.k-draghandle {
  background-repeat: repeat;
  background-position: 0 center;
}
.k-link:hover {
  text-decoration: none;
}
.k-state-highlight > .k-link {
  color: inherit;
}
/* widget */
.k-textbox > input,
.k-input[type="text"],
.k-input[type="number"],
.k-textbox,
.k-picker-wrap .k-input,
.k-button {
  font-size: 100%;
  font-family: inherit;
  border-style: solid;
  border-width: 1px;
  -webkit-appearance: none;
}
.k-widget,
.k-block,
.k-inline-block,
.k-draghandle {
  border-style: solid;
  border-width: 1px;
  -webkit-appearance: none;
}
.k-block,
.k-widget {
  line-height: normal;
  outline: 0;
}
.k-widget ::-ms-clear {
  width: 0;
  height: 0;
}
/* Block */
.k-block {
  padding: 2px;
}
/* button */
.k-button {
  display: inline-block;
  margin: 0;
  padding: 5px 14px 4px;
  font-family: inherit;
  line-height: 1.72em;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}
.k-button[disabled],
.k-button.k-state-disabled,
.k-state-disabled .k-button,
.k-state-disabled .k-button:hover,
.k-button.k-state-disabled:hover,
.k-state-disabled .k-button:active,
.k-button.k-state-disabled:active {
  cursor: default;
}
a.k-button {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-decoration: none;
}
/* Override the important default line-height in Firefox 4+ */
.k-ff input.k-button {
  padding-bottom: 0.37em;
  padding-top: 0.37em;
}
button.k-button::-moz-focus-inner,
input.k-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a.k-button-expand {
  display: block;
}
button.k-button-expand,
input[type="submit"].k-button-expand,
input[type="button"].k-button-expand,
input[type="reset"].k-button-expand {
  width: 100%;
}
body .k-button-icon,
body .k-split-button-arrow {
  padding-left: .4em;
  padding-right: .4em;
}
.k-button-icontext {
  overflow: visible;
  /*IE9*/
}
.k-toolbar .k-button-icontext {
  padding-right: .8em;
}
.k-button-icontext .k-icon,
.k-button-icontext .k-image,
.k-button-icontext .k-sprite {
  margin-right: 3px;
  margin-right: .3rem;
  margin-left: -3px;
  margin-left: -0.3rem;
}
.k-button.k-button-icontext .k-icon,
.k-button.k-button-icontext .k-image {
  vertical-align: text-top;
}
html body .k-button-bare {
  background: none !important;
  /*spares long selectors*/
  color: inherit;
  border-width: 0;
}
html body .k-button-bare.k-upload-button:hover {
  color: inherit;
}
/* link */
.k-link {
  cursor: pointer;
  outline: 0;
  text-decoration: none;
}
.k-grid-header span.k-link {
  cursor: default;
}
/* states */
.k-state-disabled,
.k-state-disabled .k-link,
.k-state-disabled .k-icon,
.k-state-disabled .k-button,
.k-state-disabled .k-draghandle,
.k-state-disabled .k-upload-button input {
  cursor: default !important;
  outline: 0;
}
@media print {
  .k-state-disabled,
  .k-state-disabled .k-input {
    opacity: 1 !important;
  }
}
.k-state-error {
  border-style: ridge;
}
.k-state-empty {
  font-style: italic;
}
/* icons */
.k-icon,
.k-sprite,
.k-button-group .k-tool-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 0;
  text-align: center;
  -ms-high-contrast-adjust: none;
}
.k-icon.k-i-none {
  background-image: none !important;
  /* should never be a background on these */
}
/* In IE7 vertical align: middle can't be overridden */
.k-ie8 .k-icon,
.k-ie8 .k-sprite,
.k-ie8 .k-button-group .k-tool-icon {
  vertical-align: middle;
}
:root * > .k-icon,
:root * > .k-sprite,
:root * > .k-button-group .k-tool-icon {
  vertical-align: middle;
}
.k-icon,
.k-sprite {
  background-color: transparent;
}
.k-numerictextbox .k-select .k-link span.k-i-arrow-n {
  background-position: 0 -3px;
}
.k-numerictextbox .k-select .k-link span.k-i-arrow-s {
  background-position: 0 -35px;
}
.k-state-selected .k-i-arrow-n {
  background-position: -16px 0px;
}
.k-link:not(.k-state-disabled):hover > .k-state-selected .k-i-arrow-n,
.k-state-hover > .k-state-selected .k-i-arrow-n,
.k-state-hover > * > .k-state-selected .k-i-arrow-n,
.k-button:not(.k-state-disabled):hover .k-state-selected .k-i-arrow-n,
.k-textbox:hover .k-state-selected .k-i-arrow-n,
.k-button:active .k-state-selected .k-i-arrow-n {
  background-position: -32px 0px;
}
.k-numerictextbox .k-link.k-state-selected span.k-i-arrow-n,
.k-numerictextbox .k-state-hover .k-link span.k-i-arrow-n {
  background-position: -16px -3px;
}
.k-state-selected .k-i-arrow-s {
  background-position: -16px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-state-selected .k-i-arrow-s,
.k-state-hover > .k-state-selected .k-i-arrow-s,
.k-state-hover > * > .k-state-selected .k-i-arrow-s,
.k-button:not(.k-state-disabled):hover .k-state-selected .k-i-arrow-s,
.k-textbox:hover .k-state-selected .k-i-arrow-s,
.k-button:active .k-state-selected .k-i-arrow-s {
  background-position: -32px -32px;
}
.k-numerictextbox .k-link.k-state-selected span.k-i-arrow-s,
.k-numerictextbox .k-state-hover .k-link span.k-i-arrow-s {
  background-position: -16px -35px;
}
.k-grid-header th > .k-link:hover span.k-i-arrow-n {
  background-position: 0px 0px;
}
.k-grid-header th > .k-link:hover span.k-i-arrow-s {
  background-position: 0px -32px;
}
.k-group-indicator .k-link:hover span.k-si-arrow-n {
  background-position: 0 -129px;
}
.k-group-indicator .k-link:hover span.k-si-arrow-s {
  background-position: 0 -159px;
}
.k-group-indicator .k-button:hover span.k-group-delete {
  background-position: -32px -16px;
}
.k-scheduler .k-scheduler-toolbar .k-nav-current .k-link .k-i-calendar {
  background-position: -32px -176px;
}
.k-i-arrow-n {
  background-position: 0px 0px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrow-n,
.k-state-hover > .k-i-arrow-n,
.k-state-hover > * > .k-i-arrow-n,
.k-button:not(.k-state-disabled):hover .k-i-arrow-n,
.k-textbox:hover .k-i-arrow-n,
.k-button:active .k-i-arrow-n {
  background-position: -16px 0px;
}
.k-i-arrow-e {
  background-position: 0px -16px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrow-e,
.k-state-hover > .k-i-arrow-e,
.k-state-hover > * > .k-i-arrow-e,
.k-button:not(.k-state-disabled):hover .k-i-arrow-e,
.k-textbox:hover .k-i-arrow-e,
.k-button:active .k-i-arrow-e {
  background-position: -16px -16px;
}
.k-rtl .k-i-arrow-w {
  background-position: 0px -16px;
}
.k-rtl .k-link:not(.k-state-disabled):hover > .k-i-arrow-w,
.k-rtl .k-state-hover > .k-i-arrow-w,
.k-rtl .k-state-hover > * > .k-i-arrow-w,
.k-rtl .k-button:not(.k-state-disabled):hover .k-i-arrow-w,
.k-rtl .k-textbox:hover .k-i-arrow-w,
.k-rtl .k-button:active .k-i-arrow-w {
  background-position: -16px -16px;
}
.k-i-arrow-s {
  background-position: 0px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrow-s,
.k-state-hover > .k-i-arrow-s,
.k-state-hover > * > .k-i-arrow-s,
.k-button:not(.k-state-disabled):hover .k-i-arrow-s,
.k-textbox:hover .k-i-arrow-s,
.k-button:active .k-i-arrow-s {
  background-position: -16px -32px;
}
.k-i-arrow-w {
  background-position: 0px -48px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrow-w,
.k-state-hover > .k-i-arrow-w,
.k-state-hover > * > .k-i-arrow-w,
.k-button:not(.k-state-disabled):hover .k-i-arrow-w,
.k-textbox:hover .k-i-arrow-w,
.k-button:active .k-i-arrow-w {
  background-position: -16px -48px;
}
.k-rtl .k-i-arrow-e {
  background-position: 0px -48px;
}
.k-rtl .k-link:not(.k-state-disabled):hover > .k-i-arrow-e,
.k-rtl .k-state-hover > .k-i-arrow-e,
.k-rtl .k-state-hover > * > .k-i-arrow-e,
.k-rtl .k-button:not(.k-state-disabled):hover .k-i-arrow-e,
.k-rtl .k-textbox:hover .k-i-arrow-e,
.k-rtl .k-button:active .k-i-arrow-e {
  background-position: -16px -48px;
}
.k-i-seek-n {
  background-position: 0px -64px;
}
.k-link:not(.k-state-disabled):hover > .k-i-seek-n,
.k-state-hover > .k-i-seek-n,
.k-state-hover > * > .k-i-seek-n,
.k-button:not(.k-state-disabled):hover .k-i-seek-n,
.k-textbox:hover .k-i-seek-n,
.k-button:active .k-i-seek-n {
  background-position: -16px -64px;
}
.k-i-seek-e {
  background-position: 0px -80px;
}
.k-link:not(.k-state-disabled):hover > .k-i-seek-e,
.k-state-hover > .k-i-seek-e,
.k-state-hover > * > .k-i-seek-e,
.k-button:not(.k-state-disabled):hover .k-i-seek-e,
.k-textbox:hover .k-i-seek-e,
.k-button:active .k-i-seek-e {
  background-position: -16px -80px;
}
.k-rtl .k-i-seek-w {
  background-position: 0px -80px;
}
.k-rtl .k-link:not(.k-state-disabled):hover > .k-i-seek-w,
.k-rtl .k-state-hover > .k-i-seek-w,
.k-rtl .k-state-hover > * > .k-i-seek-w,
.k-rtl .k-button:not(.k-state-disabled):hover .k-i-seek-w,
.k-rtl .k-textbox:hover .k-i-seek-w,
.k-rtl .k-button:active .k-i-seek-w {
  background-position: -16px -80px;
}
.k-i-seek-s {
  background-position: 0px -96px;
}
.k-link:not(.k-state-disabled):hover > .k-i-seek-s,
.k-state-hover > .k-i-seek-s,
.k-state-hover > * > .k-i-seek-s,
.k-button:not(.k-state-disabled):hover .k-i-seek-s,
.k-textbox:hover .k-i-seek-s,
.k-button:active .k-i-seek-s {
  background-position: -16px -96px;
}
.k-i-seek-w {
  background-position: 0px -112px;
}
.k-link:not(.k-state-disabled):hover > .k-i-seek-w,
.k-state-hover > .k-i-seek-w,
.k-state-hover > * > .k-i-seek-w,
.k-button:not(.k-state-disabled):hover .k-i-seek-w,
.k-textbox:hover .k-i-seek-w,
.k-button:active .k-i-seek-w {
  background-position: -16px -112px;
}
.k-rtl .k-i-seek-e {
  background-position: 0px -112px;
}
.k-rtl .k-link:not(.k-state-disabled):hover > .k-i-seek-e,
.k-rtl .k-state-hover > .k-i-seek-e,
.k-rtl .k-state-hover > * > .k-i-seek-e,
.k-rtl .k-button:not(.k-state-disabled):hover .k-i-seek-e,
.k-rtl .k-textbox:hover .k-i-seek-e,
.k-rtl .k-button:active .k-i-seek-e {
  background-position: -16px -112px;
}
.k-si-arrow-n {
  background-position: 0 -129px;
}
.k-link:not(.k-state-disabled):hover > .k-si-arrow-n,
.k-state-hover > .k-si-arrow-n,
.k-state-hover > * > .k-si-arrow-n,
.k-button:not(.k-state-disabled):hover .k-si-arrow-n,
.k-textbox:hover .k-si-arrow-n,
.k-button:active .k-si-arrow-n {
  background-position: -16px -129px;
}
.k-si-arrow-e {
  background-position: 0px -144px;
}
.k-link:not(.k-state-disabled):hover > .k-si-arrow-e,
.k-state-hover > .k-si-arrow-e,
.k-state-hover > * > .k-si-arrow-e,
.k-button:not(.k-state-disabled):hover .k-si-arrow-e,
.k-textbox:hover .k-si-arrow-e,
.k-button:active .k-si-arrow-e {
  background-position: -16px -144px;
}
.k-si-arrow-s {
  background-position: 0 -159px;
}
.k-link:not(.k-state-disabled):hover > .k-si-arrow-s,
.k-state-hover > .k-si-arrow-s,
.k-state-hover > * > .k-si-arrow-s,
.k-button:not(.k-state-disabled):hover .k-si-arrow-s,
.k-textbox:hover .k-si-arrow-s,
.k-button:active .k-si-arrow-s {
  background-position: -16px -159px;
}
.k-si-arrow-w {
  background-position: 0px -176px;
}
.k-link:not(.k-state-disabled):hover > .k-si-arrow-w,
.k-state-hover > .k-si-arrow-w,
.k-state-hover > * > .k-si-arrow-w,
.k-button:not(.k-state-disabled):hover .k-si-arrow-w,
.k-textbox:hover .k-si-arrow-w,
.k-button:active .k-si-arrow-w {
  background-position: -16px -176px;
}
.k-i-arrowhead-n {
  background-position: 0px -256px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrowhead-n,
.k-state-hover > .k-i-arrowhead-n,
.k-state-hover > * > .k-i-arrowhead-n,
.k-button:not(.k-state-disabled):hover .k-i-arrowhead-n,
.k-textbox:hover .k-i-arrowhead-n,
.k-button:active .k-i-arrowhead-n {
  background-position: -16px -256px;
}
.k-i-arrowhead-e {
  background-position: 0px -272px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrowhead-e,
.k-state-hover > .k-i-arrowhead-e,
.k-state-hover > * > .k-i-arrowhead-e,
.k-button:not(.k-state-disabled):hover .k-i-arrowhead-e,
.k-textbox:hover .k-i-arrowhead-e,
.k-button:active .k-i-arrowhead-e {
  background-position: -16px -272px;
}
.k-i-arrowhead-s {
  background-position: 0px -288px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrowhead-s,
.k-state-hover > .k-i-arrowhead-s,
.k-state-hover > * > .k-i-arrowhead-s,
.k-button:not(.k-state-disabled):hover .k-i-arrowhead-s,
.k-textbox:hover .k-i-arrowhead-s,
.k-button:active .k-i-arrowhead-s {
  background-position: -16px -288px;
}
.k-i-arrowhead-w {
  background-position: 0px -304px;
}
.k-link:not(.k-state-disabled):hover > .k-i-arrowhead-w,
.k-state-hover > .k-i-arrowhead-w,
.k-state-hover > * > .k-i-arrowhead-w,
.k-button:not(.k-state-disabled):hover .k-i-arrowhead-w,
.k-textbox:hover .k-i-arrowhead-w,
.k-button:active .k-i-arrowhead-w {
  background-position: -16px -304px;
}
.k-i-expand,
.k-plus,
.k-plus-disabled {
  background-position: 0px -192px;
}
.k-link:not(.k-state-disabled):hover > .k-i-expand,
.k-link:not(.k-state-disabled):hover > .k-plus,
.k-link:not(.k-state-disabled):hover > .k-plus-disabled,
.k-state-hover > .k-i-expand,
.k-state-hover > .k-plus,
.k-state-hover > .k-plus-disabled,
.k-state-hover > * > .k-i-expand,
.k-state-hover > * > .k-plus,
.k-state-hover > * > .k-plus-disabled,
.k-button:not(.k-state-disabled):hover .k-i-expand,
.k-button:not(.k-state-disabled):hover .k-plus,
.k-button:not(.k-state-disabled):hover .k-plus-disabled,
.k-textbox:hover .k-i-expand,
.k-textbox:hover .k-plus,
.k-textbox:hover .k-plus-disabled,
.k-button:active .k-i-expand,
.k-button:active .k-plus,
.k-button:active .k-plus-disabled {
  background-position: -16px -192px;
}
.k-i-expand-w,
.k-rtl .k-i-expand,
.k-rtl .k-plus,
.k-rtl .k-plus-disabled {
  background-position: 0px -208px;
}
.k-link:not(.k-state-disabled):hover > .k-i-expand-w,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-i-expand,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-plus,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-plus-disabled,
.k-state-hover > .k-i-expand-w,
.k-state-hover > .k-rtl .k-i-expand,
.k-state-hover > .k-rtl .k-plus,
.k-state-hover > .k-rtl .k-plus-disabled,
.k-state-hover > * > .k-i-expand-w,
.k-state-hover > * > .k-rtl .k-i-expand,
.k-state-hover > * > .k-rtl .k-plus,
.k-state-hover > * > .k-rtl .k-plus-disabled,
.k-button:not(.k-state-disabled):hover .k-i-expand-w,
.k-button:not(.k-state-disabled):hover .k-rtl .k-i-expand,
.k-button:not(.k-state-disabled):hover .k-rtl .k-plus,
.k-button:not(.k-state-disabled):hover .k-rtl .k-plus-disabled,
.k-textbox:hover .k-i-expand-w,
.k-textbox:hover .k-rtl .k-i-expand,
.k-textbox:hover .k-rtl .k-plus,
.k-textbox:hover .k-rtl .k-plus-disabled,
.k-button:active .k-i-expand-w,
.k-button:active .k-rtl .k-i-expand,
.k-button:active .k-rtl .k-plus,
.k-button:active .k-rtl .k-plus-disabled {
  background-position: -16px -208px;
}
.k-i-collapse,
.k-minus,
.k-minus-disabled {
  background-position: 0px -224px;
}
.k-link:not(.k-state-disabled):hover > .k-i-collapse,
.k-link:not(.k-state-disabled):hover > .k-minus,
.k-link:not(.k-state-disabled):hover > .k-minus-disabled,
.k-state-hover > .k-i-collapse,
.k-state-hover > .k-minus,
.k-state-hover > .k-minus-disabled,
.k-state-hover > * > .k-i-collapse,
.k-state-hover > * > .k-minus,
.k-state-hover > * > .k-minus-disabled,
.k-button:not(.k-state-disabled):hover .k-i-collapse,
.k-button:not(.k-state-disabled):hover .k-minus,
.k-button:not(.k-state-disabled):hover .k-minus-disabled,
.k-textbox:hover .k-i-collapse,
.k-textbox:hover .k-minus,
.k-textbox:hover .k-minus-disabled,
.k-button:active .k-i-collapse,
.k-button:active .k-minus,
.k-button:active .k-minus-disabled {
  background-position: -16px -224px;
}
.k-i-collapse-w,
.k-rtl .k-i-collapse,
.k-rtl .k-minus,
.k-rtl .k-minus-disabled {
  background-position: 0px -240px;
}
.k-link:not(.k-state-disabled):hover > .k-i-collapse-w,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-i-collapse,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-minus,
.k-link:not(.k-state-disabled):hover > .k-rtl .k-minus-disabled,
.k-state-hover > .k-i-collapse-w,
.k-state-hover > .k-rtl .k-i-collapse,
.k-state-hover > .k-rtl .k-minus,
.k-state-hover > .k-rtl .k-minus-disabled,
.k-state-hover > * > .k-i-collapse-w,
.k-state-hover > * > .k-rtl .k-i-collapse,
.k-state-hover > * > .k-rtl .k-minus,
.k-state-hover > * > .k-rtl .k-minus-disabled,
.k-button:not(.k-state-disabled):hover .k-i-collapse-w,
.k-button:not(.k-state-disabled):hover .k-rtl .k-i-collapse,
.k-button:not(.k-state-disabled):hover .k-rtl .k-minus,
.k-button:not(.k-state-disabled):hover .k-rtl .k-minus-disabled,
.k-textbox:hover .k-i-collapse-w,
.k-textbox:hover .k-rtl .k-i-collapse,
.k-textbox:hover .k-rtl .k-minus,
.k-textbox:hover .k-rtl .k-minus-disabled,
.k-button:active .k-i-collapse-w,
.k-button:active .k-rtl .k-i-collapse,
.k-button:active .k-rtl .k-minus,
.k-button:active .k-rtl .k-minus-disabled {
  background-position: -16px -240px;
}
.k-i-pencil,
.k-edit {
  background-position: -32px 0px;
}
.k-link:not(.k-state-disabled):hover > .k-i-pencil,
.k-link:not(.k-state-disabled):hover > .k-edit,
.k-state-hover > .k-i-pencil,
.k-state-hover > .k-edit,
.k-state-hover > * > .k-i-pencil,
.k-state-hover > * > .k-edit,
.k-button:not(.k-state-disabled):hover .k-i-pencil,
.k-button:not(.k-state-disabled):hover .k-edit,
.k-textbox:hover .k-i-pencil,
.k-textbox:hover .k-edit,
.k-button:active .k-i-pencil,
.k-button:active .k-edit {
  background-position: -48px 0px;
}
.k-i-close,
.k-delete,
.k-group-delete {
  background-position: -32px -16px;
}
.k-link:not(.k-state-disabled):hover > .k-i-close,
.k-link:not(.k-state-disabled):hover > .k-delete,
.k-link:not(.k-state-disabled):hover > .k-group-delete,
.k-state-hover > .k-i-close,
.k-state-hover > .k-delete,
.k-state-hover > .k-group-delete,
.k-state-hover > * > .k-i-close,
.k-state-hover > * > .k-delete,
.k-state-hover > * > .k-group-delete,
.k-button:not(.k-state-disabled):hover .k-i-close,
.k-button:not(.k-state-disabled):hover .k-delete,
.k-button:not(.k-state-disabled):hover .k-group-delete,
.k-textbox:hover .k-i-close,
.k-textbox:hover .k-delete,
.k-textbox:hover .k-group-delete,
.k-button:active .k-i-close,
.k-button:active .k-delete,
.k-button:active .k-group-delete {
  background-position: -48px -16px;
}
.k-si-close {
  background-position: -160px -80px;
}
.k-link:not(.k-state-disabled):hover > .k-si-close,
.k-state-hover > .k-si-close,
.k-state-hover > * > .k-si-close,
.k-button:not(.k-state-disabled):hover .k-si-close,
.k-textbox:hover .k-si-close,
.k-button:active .k-si-close {
  background-position: -176px -80px;
}
.k-multiselect .k-delete {
  background-position: -160px -80px;
}
.k-multiselect .k-state-hover .k-delete {
  background-position: -176px -80px;
}
.k-i-tick,
.k-insert,
.k-update {
  background-position: -32px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-i-tick,
.k-link:not(.k-state-disabled):hover > .k-insert,
.k-link:not(.k-state-disabled):hover > .k-update,
.k-state-hover > .k-i-tick,
.k-state-hover > .k-insert,
.k-state-hover > .k-update,
.k-state-hover > * > .k-i-tick,
.k-state-hover > * > .k-insert,
.k-state-hover > * > .k-update,
.k-button:not(.k-state-disabled):hover .k-i-tick,
.k-button:not(.k-state-disabled):hover .k-insert,
.k-button:not(.k-state-disabled):hover .k-update,
.k-textbox:hover .k-i-tick,
.k-textbox:hover .k-insert,
.k-textbox:hover .k-update,
.k-button:active .k-i-tick,
.k-button:active .k-insert,
.k-button:active .k-update {
  background-position: -48px -32px;
}
.k-check:checked,
.k-mobile-list .k-edit-field [type=checkbox],
.k-mobile-list .k-edit-field [type=radio] {
  background-position: -32px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-check:checked,
.k-link:not(.k-state-disabled):hover > .k-mobile-list .k-edit-field [type=checkbox],
.k-link:not(.k-state-disabled):hover > .k-mobile-list .k-edit-field [type=radio],
.k-state-hover > .k-check:checked,
.k-state-hover > .k-mobile-list .k-edit-field [type=checkbox],
.k-state-hover > .k-mobile-list .k-edit-field [type=radio],
.k-state-hover > * > .k-check:checked,
.k-state-hover > * > .k-mobile-list .k-edit-field [type=checkbox],
.k-state-hover > * > .k-mobile-list .k-edit-field [type=radio],
.k-button:not(.k-state-disabled):hover .k-check:checked,
.k-button:not(.k-state-disabled):hover .k-mobile-list .k-edit-field [type=checkbox],
.k-button:not(.k-state-disabled):hover .k-mobile-list .k-edit-field [type=radio],
.k-textbox:hover .k-check:checked,
.k-textbox:hover .k-mobile-list .k-edit-field [type=checkbox],
.k-textbox:hover .k-mobile-list .k-edit-field [type=radio],
.k-button:active .k-check:checked,
.k-button:active .k-mobile-list .k-edit-field [type=checkbox],
.k-button:active .k-mobile-list .k-edit-field [type=radio] {
  background-position: -48px -32px;
}
.k-i-cancel,
.k-cancel,
.k-denied {
  background-position: -32px -48px;
}
.k-link:not(.k-state-disabled):hover > .k-i-cancel,
.k-link:not(.k-state-disabled):hover > .k-cancel,
.k-link:not(.k-state-disabled):hover > .k-denied,
.k-state-hover > .k-i-cancel,
.k-state-hover > .k-cancel,
.k-state-hover > .k-denied,
.k-state-hover > * > .k-i-cancel,
.k-state-hover > * > .k-cancel,
.k-state-hover > * > .k-denied,
.k-button:not(.k-state-disabled):hover .k-i-cancel,
.k-button:not(.k-state-disabled):hover .k-cancel,
.k-button:not(.k-state-disabled):hover .k-denied,
.k-textbox:hover .k-i-cancel,
.k-textbox:hover .k-cancel,
.k-textbox:hover .k-denied,
.k-button:active .k-i-cancel,
.k-button:active .k-cancel,
.k-button:active .k-denied {
  background-position: -48px -48px;
}
.k-i-plus,
.k-add {
  background-position: -32px -64px;
}
.k-link:not(.k-state-disabled):hover > .k-i-plus,
.k-link:not(.k-state-disabled):hover > .k-add,
.k-state-hover > .k-i-plus,
.k-state-hover > .k-add,
.k-state-hover > * > .k-i-plus,
.k-state-hover > * > .k-add,
.k-button:not(.k-state-disabled):hover .k-i-plus,
.k-button:not(.k-state-disabled):hover .k-add,
.k-textbox:hover .k-i-plus,
.k-textbox:hover .k-add,
.k-button:active .k-i-plus,
.k-button:active .k-add {
  background-position: -48px -64px;
}
.k-i-funnel,
.k-filter {
  background-position: -32px -80px;
}
.k-link:not(.k-state-disabled):hover > .k-i-funnel,
.k-link:not(.k-state-disabled):hover > .k-filter,
.k-state-hover > .k-i-funnel,
.k-state-hover > .k-filter,
.k-state-hover > * > .k-i-funnel,
.k-state-hover > * > .k-filter,
.k-button:not(.k-state-disabled):hover .k-i-funnel,
.k-button:not(.k-state-disabled):hover .k-filter,
.k-textbox:hover .k-i-funnel,
.k-textbox:hover .k-filter,
.k-button:active .k-i-funnel,
.k-button:active .k-filter {
  background-position: -48px -80px;
}
.k-i-funnel-clear,
.k-clear-filter {
  background-position: -32px -96px;
}
.k-link:not(.k-state-disabled):hover > .k-i-funnel-clear,
.k-link:not(.k-state-disabled):hover > .k-clear-filter,
.k-state-hover > .k-i-funnel-clear,
.k-state-hover > .k-clear-filter,
.k-state-hover > * > .k-i-funnel-clear,
.k-state-hover > * > .k-clear-filter,
.k-button:not(.k-state-disabled):hover .k-i-funnel-clear,
.k-button:not(.k-state-disabled):hover .k-clear-filter,
.k-textbox:hover .k-i-funnel-clear,
.k-textbox:hover .k-clear-filter,
.k-button:active .k-i-funnel-clear,
.k-button:active .k-clear-filter {
  background-position: -48px -96px;
}
.k-i-lock {
  background-position: -64px 0px;
}
.k-link:not(.k-state-disabled):hover > .k-i-lock,
.k-state-hover > .k-i-lock,
.k-state-hover > * > .k-i-lock,
.k-button:not(.k-state-disabled):hover .k-i-lock,
.k-textbox:hover .k-i-lock,
.k-button:active .k-i-lock {
  background-position: -80px 0px;
}
.k-i-unlock {
  background-position: -64px -16px;
}
.k-link:not(.k-state-disabled):hover > .k-i-unlock,
.k-state-hover > .k-i-unlock,
.k-state-hover > * > .k-i-unlock,
.k-button:not(.k-state-disabled):hover .k-i-unlock,
.k-textbox:hover .k-i-unlock,
.k-button:active .k-i-unlock {
  background-position: -80px -16px;
}
.k-i-refresh {
  background-position: -32px -112px;
}
.k-link:not(.k-state-disabled):hover > .k-i-refresh,
.k-state-hover > .k-i-refresh,
.k-state-hover > * > .k-i-refresh,
.k-button:not(.k-state-disabled):hover .k-i-refresh,
.k-textbox:hover .k-i-refresh,
.k-button:active .k-i-refresh {
  background-position: -48px -112px;
}
.k-i-exception {
  background-position: -160px -304px;
}
.k-link:not(.k-state-disabled):hover > .k-i-exception,
.k-state-hover > .k-i-exception,
.k-state-hover > * > .k-i-exception,
.k-button:not(.k-state-disabled):hover .k-i-exception,
.k-textbox:hover .k-i-exception,
.k-button:active .k-i-exception {
  background-position: -176px -304px;
}
.k-i-restore {
  background-position: -32px -128px;
}
.k-link:not(.k-state-disabled):hover > .k-i-restore,
.k-state-hover > .k-i-restore,
.k-state-hover > * > .k-i-restore,
.k-button:not(.k-state-disabled):hover .k-i-restore,
.k-textbox:hover .k-i-restore,
.k-button:active .k-i-restore {
  background-position: -48px -128px;
}
.k-i-maximize {
  background-position: -32px -144px;
}
.k-link:not(.k-state-disabled):hover > .k-i-maximize,
.k-state-hover > .k-i-maximize,
.k-state-hover > * > .k-i-maximize,
.k-button:not(.k-state-disabled):hover .k-i-maximize,
.k-textbox:hover .k-i-maximize,
.k-button:active .k-i-maximize {
  background-position: -48px -144px;
}
.k-i-minimize {
  background-position: -64px -288px;
}
.k-link:not(.k-state-disabled):hover > .k-i-minimize,
.k-state-hover > .k-i-minimize,
.k-state-hover > * > .k-i-minimize,
.k-button:not(.k-state-disabled):hover .k-i-minimize,
.k-textbox:hover .k-i-minimize,
.k-button:active .k-i-minimize {
  background-position: -80px -288px;
}
.k-i-pin {
  background-position: -160px -256px;
}
.k-link:not(.k-state-disabled):hover > .k-i-pin,
.k-state-hover > .k-i-pin,
.k-state-hover > * > .k-i-pin,
.k-button:not(.k-state-disabled):hover .k-i-pin,
.k-textbox:hover .k-i-pin,
.k-button:active .k-i-pin {
  background-position: -176px -256px;
}
.k-i-unpin {
  background-position: -160px -272px;
}
.k-link:not(.k-state-disabled):hover > .k-i-unpin,
.k-state-hover > .k-i-unpin,
.k-state-hover > * > .k-i-unpin,
.k-button:not(.k-state-disabled):hover .k-i-unpin,
.k-textbox:hover .k-i-unpin,
.k-button:active .k-i-unpin {
  background-position: -176px -272px;
}
.k-resize-se {
  background-position: -32px -160px;
}
.k-link:not(.k-state-disabled):hover > .k-resize-se,
.k-state-hover > .k-resize-se,
.k-state-hover > * > .k-resize-se,
.k-button:not(.k-state-disabled):hover .k-resize-se,
.k-textbox:hover .k-resize-se,
.k-button:active .k-resize-se {
  background-position: -48px -160px;
}
.k-i-calendar {
  background-position: -32px -176px;
}
.k-link:not(.k-state-disabled):hover > .k-i-calendar,
.k-state-hover > .k-i-calendar,
.k-state-hover > * > .k-i-calendar,
.k-button:not(.k-state-disabled):hover .k-i-calendar,
.k-textbox:hover .k-i-calendar,
.k-button:active .k-i-calendar {
  background-position: -48px -176px;
}
.k-i-clock {
  background-position: -32px -192px;
}
.k-link:not(.k-state-disabled):hover > .k-i-clock,
.k-state-hover > .k-i-clock,
.k-state-hover > * > .k-i-clock,
.k-button:not(.k-state-disabled):hover .k-i-clock,
.k-textbox:hover .k-i-clock,
.k-button:active .k-i-clock {
  background-position: -48px -192px;
}
.k-si-plus {
  background-position: -32px -208px;
}
.k-link:not(.k-state-disabled):hover > .k-si-plus,
.k-state-hover > .k-si-plus,
.k-state-hover > * > .k-si-plus,
.k-button:not(.k-state-disabled):hover .k-si-plus,
.k-textbox:hover .k-si-plus,
.k-button:active .k-si-plus {
  background-position: -48px -208px;
}
.k-si-minus {
  background-position: -32px -224px;
}
.k-link:not(.k-state-disabled):hover > .k-si-minus,
.k-state-hover > .k-si-minus,
.k-state-hover > * > .k-si-minus,
.k-button:not(.k-state-disabled):hover .k-si-minus,
.k-textbox:hover .k-si-minus,
.k-button:active .k-si-minus {
  background-position: -48px -224px;
}
.k-i-search {
  background-position: -32px -240px;
}
.k-link:not(.k-state-disabled):hover > .k-i-search,
.k-state-hover > .k-i-search,
.k-state-hover > * > .k-i-search,
.k-button:not(.k-state-disabled):hover .k-i-search,
.k-textbox:hover .k-i-search,
.k-button:active .k-i-search {
  background-position: -48px -240px;
}
.k-i-custom {
  background-position: -115px -113px;
}
.k-link:not(.k-state-disabled):hover > .k-i-custom,
.k-state-hover > .k-i-custom,
.k-state-hover > * > .k-i-custom,
.k-button:not(.k-state-disabled):hover .k-i-custom,
.k-textbox:hover .k-i-custom,
.k-button:active .k-i-custom {
  background-position: -141px -113px;
}
.k-editor .k-i-custom {
  background-position: -111px -109px;
}
.k-viewHtml {
  background-position: -288px -120px;
}
.k-i-insert-n,
.k-insert-top {
  background-position: -160px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-i-insert-n,
.k-link:not(.k-state-disabled):hover > .k-insert-top,
.k-state-hover > .k-i-insert-n,
.k-state-hover > .k-insert-top,
.k-state-hover > * > .k-i-insert-n,
.k-state-hover > * > .k-insert-top,
.k-button:not(.k-state-disabled):hover .k-i-insert-n,
.k-button:not(.k-state-disabled):hover .k-insert-top,
.k-textbox:hover .k-i-insert-n,
.k-textbox:hover .k-insert-top,
.k-button:active .k-i-insert-n,
.k-button:active .k-insert-top {
  background-position: -176px -32px;
}
.k-i-insert-m,
.k-insert-middle {
  background-position: -160px -48px;
}
.k-link:not(.k-state-disabled):hover > .k-i-insert-m,
.k-link:not(.k-state-disabled):hover > .k-insert-middle,
.k-state-hover > .k-i-insert-m,
.k-state-hover > .k-insert-middle,
.k-state-hover > * > .k-i-insert-m,
.k-state-hover > * > .k-insert-middle,
.k-button:not(.k-state-disabled):hover .k-i-insert-m,
.k-button:not(.k-state-disabled):hover .k-insert-middle,
.k-textbox:hover .k-i-insert-m,
.k-textbox:hover .k-insert-middle,
.k-button:active .k-i-insert-m,
.k-button:active .k-insert-middle {
  background-position: -176px -48px;
}
.k-i-insert-s,
.k-insert-bottom {
  background-position: -160px -64px;
}
.k-link:not(.k-state-disabled):hover > .k-i-insert-s,
.k-link:not(.k-state-disabled):hover > .k-insert-bottom,
.k-state-hover > .k-i-insert-s,
.k-state-hover > .k-insert-bottom,
.k-state-hover > * > .k-i-insert-s,
.k-state-hover > * > .k-insert-bottom,
.k-button:not(.k-state-disabled):hover .k-i-insert-s,
.k-button:not(.k-state-disabled):hover .k-insert-bottom,
.k-textbox:hover .k-i-insert-s,
.k-textbox:hover .k-insert-bottom,
.k-button:active .k-i-insert-s,
.k-button:active .k-insert-bottom {
  background-position: -176px -64px;
}
.k-drop-hint {
  background-position: 0 -326px;
}
.k-i-note,
.k-warning {
  background-position: -160px -240px;
}
.k-link:not(.k-state-disabled):hover > .k-i-note,
.k-link:not(.k-state-disabled):hover > .k-warning,
.k-state-hover > .k-i-note,
.k-state-hover > .k-warning,
.k-state-hover > * > .k-i-note,
.k-state-hover > * > .k-warning,
.k-button:not(.k-state-disabled):hover .k-i-note,
.k-button:not(.k-state-disabled):hover .k-warning,
.k-textbox:hover .k-i-note,
.k-textbox:hover .k-warning,
.k-button:active .k-i-note,
.k-button:active .k-warning {
  background-position: -176px -240px;
}
.k-i-sort-asc {
  background-position: -112px -240px;
}
.k-link:not(.k-state-disabled):hover > .k-i-sort-asc,
.k-state-hover > .k-i-sort-asc,
.k-state-hover > * > .k-i-sort-asc,
.k-button:not(.k-state-disabled):hover .k-i-sort-asc,
.k-textbox:hover .k-i-sort-asc,
.k-button:active .k-i-sort-asc {
  background-position: -128px -240px;
}
.k-i-sort-desc {
  background-position: -112px -256px;
}
.k-link:not(.k-state-disabled):hover > .k-i-sort-desc,
.k-state-hover > .k-i-sort-desc,
.k-state-hover > * > .k-i-sort-desc,
.k-button:not(.k-state-disabled):hover .k-i-sort-desc,
.k-textbox:hover .k-i-sort-desc,
.k-button:active .k-i-sort-desc {
  background-position: -128px -256px;
}
.k-i-group {
  background-position: -112px -272px;
}
.k-link:not(.k-state-disabled):hover > .k-i-group,
.k-state-hover > .k-i-group,
.k-state-hover > * > .k-i-group,
.k-button:not(.k-state-disabled):hover .k-i-group,
.k-textbox:hover .k-i-group,
.k-button:active .k-i-group {
  background-position: -128px -272px;
}
.k-i-ungroup {
  background-position: -112px -288px;
}
.k-link:not(.k-state-disabled):hover > .k-i-ungroup,
.k-state-hover > .k-i-ungroup,
.k-state-hover > * > .k-i-ungroup,
.k-button:not(.k-state-disabled):hover .k-i-ungroup,
.k-textbox:hover .k-i-ungroup,
.k-button:active .k-i-ungroup {
  background-position: -128px -288px;
}
.k-i-columns {
  background-position: -112px -304px;
}
.k-link:not(.k-state-disabled):hover > .k-i-columns,
.k-state-hover > .k-i-columns,
.k-state-hover > * > .k-i-columns,
.k-button:not(.k-state-disabled):hover .k-i-columns,
.k-textbox:hover .k-i-columns,
.k-button:active .k-i-columns {
  background-position: -128px -304px;
}
.k-i-hbars {
  background-position: -64px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-i-hbars,
.k-state-hover > .k-i-hbars,
.k-state-hover > * > .k-i-hbars,
.k-button:not(.k-state-disabled):hover .k-i-hbars,
.k-textbox:hover .k-i-hbars,
.k-button:active .k-i-hbars {
  background-position: -80px -32px;
}
.k-i-vbars {
  background-position: -64px -48px;
}
.k-link:not(.k-state-disabled):hover > .k-i-vbars,
.k-state-hover > .k-i-vbars,
.k-state-hover > * > .k-i-vbars,
.k-button:not(.k-state-disabled):hover .k-i-vbars,
.k-textbox:hover .k-i-vbars,
.k-button:active .k-i-vbars {
  background-position: -80px -48px;
}
.k-i-sum {
  background-position: -64px -64px;
}
.k-link:not(.k-state-disabled):hover > .k-i-sum,
.k-state-hover > .k-i-sum,
.k-state-hover > * > .k-i-sum,
.k-button:not(.k-state-disabled):hover .k-i-sum,
.k-textbox:hover .k-i-sum,
.k-button:active .k-i-sum {
  background-position: -80px -64px;
}
.k-i-pdf {
  background-position: -64px -80px;
}
.k-link:not(.k-state-disabled):hover > .k-i-pdf,
.k-state-hover > .k-i-pdf,
.k-state-hover > * > .k-i-pdf,
.k-button:not(.k-state-disabled):hover .k-i-pdf,
.k-textbox:hover .k-i-pdf,
.k-button:active .k-i-pdf {
  background-position: -80px -80px;
}
.k-i-excel {
  background-position: -64px -96px;
}
.k-link:not(.k-state-disabled):hover > .k-i-excel,
.k-state-hover > .k-i-excel,
.k-state-hover > * > .k-i-excel,
.k-button:not(.k-state-disabled):hover .k-i-excel,
.k-textbox:hover .k-i-excel,
.k-button:active .k-i-excel {
  background-position: -80px -96px;
}
.k-i-rotatecw {
  background-position: -64px -112px;
}
.k-link:not(.k-state-disabled):hover > .k-i-rotatecw,
.k-state-hover > .k-i-rotatecw,
.k-state-hover > * > .k-i-rotatecw,
.k-button:not(.k-state-disabled):hover .k-i-rotatecw,
.k-textbox:hover .k-i-rotatecw,
.k-button:active .k-i-rotatecw {
  background-position: -80px -112px;
}
.k-i-rotateccw {
  background-position: -64px -128px;
}
.k-link:not(.k-state-disabled):hover > .k-i-rotateccw,
.k-state-hover > .k-i-rotateccw,
.k-state-hover > * > .k-i-rotateccw,
.k-button:not(.k-state-disabled):hover .k-i-rotateccw,
.k-textbox:hover .k-i-rotateccw,
.k-button:active .k-i-rotateccw {
  background-position: -80px -128px;
}
.k-i-undo {
  background-position: -64px -160px;
}
.k-link:not(.k-state-disabled):hover > .k-i-undo,
.k-state-hover > .k-i-undo,
.k-state-hover > * > .k-i-undo,
.k-button:not(.k-state-disabled):hover .k-i-undo,
.k-textbox:hover .k-i-undo,
.k-button:active .k-i-undo {
  background-position: -80px -160px;
}
.k-i-redo {
  background-position: -64px -144px;
}
.k-link:not(.k-state-disabled):hover > .k-i-redo,
.k-state-hover > .k-i-redo,
.k-state-hover > * > .k-i-redo,
.k-button:not(.k-state-disabled):hover .k-i-redo,
.k-textbox:hover .k-i-redo,
.k-button:active .k-i-redo {
  background-position: -80px -144px;
}
.k-i-shape {
  background-position: -64px -176px;
}
.k-link:not(.k-state-disabled):hover > .k-i-shape,
.k-state-hover > .k-i-shape,
.k-state-hover > * > .k-i-shape,
.k-button:not(.k-state-disabled):hover .k-i-shape,
.k-textbox:hover .k-i-shape,
.k-button:active .k-i-shape {
  background-position: -80px -176px;
}
.k-i-connector {
  background-position: -64px -192px;
}
.k-link:not(.k-state-disabled):hover > .k-i-connector,
.k-state-hover > .k-i-connector,
.k-state-hover > * > .k-i-connector,
.k-button:not(.k-state-disabled):hover .k-i-connector,
.k-textbox:hover .k-i-connector,
.k-button:active .k-i-connector {
  background-position: -80px -192px;
}
.k-i-kpi {
  background-position: -64px -208px;
}
.k-link:not(.k-state-disabled):hover > .k-i-kpi,
.k-state-hover > .k-i-kpi,
.k-state-hover > * > .k-i-kpi,
.k-button:not(.k-state-disabled):hover .k-i-kpi,
.k-textbox:hover .k-i-kpi,
.k-button:active .k-i-kpi {
  background-position: -80px -208px;
}
.k-i-dimension {
  background-position: -64px -224px;
}
.k-link:not(.k-state-disabled):hover > .k-i-dimension,
.k-state-hover > .k-i-dimension,
.k-state-hover > * > .k-i-dimension,
.k-button:not(.k-state-disabled):hover .k-i-dimension,
.k-textbox:hover .k-i-dimension,
.k-button:active .k-i-dimension {
  background-position: -80px -224px;
}
.k-file {
  background-position: 0px 0px;
}
.k-link:not(.k-state-disabled):hover > .k-file,
.k-state-hover > .k-file,
.k-state-hover > * > .k-file,
.k-button:not(.k-state-disabled):hover .k-file,
.k-textbox:hover .k-file,
.k-button:active .k-file {
  background-position: -16px 0px;
}
.k-i-folder-add,
.k-addfolder {
  background-position: -32px -272px;
}
.k-link:not(.k-state-disabled):hover > .k-i-folder-add,
.k-link:not(.k-state-disabled):hover > .k-addfolder,
.k-state-hover > .k-i-folder-add,
.k-state-hover > .k-addfolder,
.k-state-hover > * > .k-i-folder-add,
.k-state-hover > * > .k-addfolder,
.k-button:not(.k-state-disabled):hover .k-i-folder-add,
.k-button:not(.k-state-disabled):hover .k-addfolder,
.k-textbox:hover .k-i-folder-add,
.k-textbox:hover .k-addfolder,
.k-button:active .k-i-folder-add,
.k-button:active .k-addfolder {
  background-position: -48px -272px;
}
.k-i-folder-up,
.k-goup {
  background-position: -32px -288px;
}
.k-link:not(.k-state-disabled):hover > .k-i-folder-up,
.k-link:not(.k-state-disabled):hover > .k-goup,
.k-state-hover > .k-i-folder-up,
.k-state-hover > .k-goup,
.k-state-hover > * > .k-i-folder-up,
.k-state-hover > * > .k-goup,
.k-button:not(.k-state-disabled):hover .k-i-folder-up,
.k-button:not(.k-state-disabled):hover .k-goup,
.k-textbox:hover .k-i-folder-up,
.k-textbox:hover .k-goup,
.k-button:active .k-i-folder-up,
.k-button:active .k-goup {
  background-position: -48px -288px;
}
.k-i-more {
  background-position: -64px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-i-more,
.k-state-hover > .k-i-more,
.k-state-hover > * > .k-i-more,
.k-button:not(.k-state-disabled):hover .k-i-more,
.k-textbox:hover .k-i-more,
.k-button:active .k-i-more {
  background-position: -80px -32px;
}
.k-i-gantt-toggle {
  background-position: -64px -240px;
}
.k-link:not(.k-state-disabled):hover > .k-i-gantt-toggle,
.k-state-hover > .k-i-gantt-toggle,
.k-state-hover > * > .k-i-gantt-toggle,
.k-button:not(.k-state-disabled):hover .k-i-gantt-toggle,
.k-textbox:hover .k-i-gantt-toggle,
.k-button:active .k-i-gantt-toggle {
  background-position: -80px -240px;
}
.k-file > .k-icon {
  background-position: -115px -91px;
}
.k-image {
  border: 0;
}
.k-breadcrumbs:hover .k-i-arrow-n {
  background-position: 0 0;
}
.k-breadcrumbs:hover .k-i-arrow-e {
  background-position: 0 -16px;
}
/* Dropdown icon in k-scheduler-views */
.k-pager-numbers .k-current-page .k-link:after,
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
.k-gantt-views > .k-current-view > .k-link:after {
  background-position: 0px -32px;
}
.k-link:not(.k-state-disabled):hover > .k-pager-numbers .k-current-page .k-link:after,
.k-link:not(.k-state-disabled):hover > .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
.k-link:not(.k-state-disabled):hover > .k-gantt-views > .k-current-view > .k-link:after,
.k-state-hover > .k-pager-numbers .k-current-page .k-link:after,
.k-state-hover > .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
.k-state-hover > .k-gantt-views > .k-current-view > .k-link:after,
.k-state-hover > * > .k-pager-numbers .k-current-page .k-link:after,
.k-state-hover > * > .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
.k-state-hover > * > .k-gantt-views > .k-current-view > .k-link:after,
.k-button:not(.k-state-disabled):hover .k-pager-numbers .k-current-page .k-link:after,
.k-button:not(.k-state-disabled):hover .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
.k-button:not(.k-state-disabled):hover .k-gantt-views > .k-current-view > .k-link:after,
.k-textbox:hover .k-pager-numbers .k-current-page .k-link:after,
.k-textbox:hover .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
.k-textbox:hover .k-gantt-views > .k-current-view > .k-link:after,
.k-button:active .k-pager-numbers .k-current-page .k-link:after,
.k-button:active .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view .k-link:after,
.k-button:active .k-gantt-views > .k-current-view > .k-link:after {
  background-position: -16px -32px;
}
/* Colors */
html .k-success-colored {
  color: #507f50;
  border-color: #d0dfd0;
  background-color: #f0fff0;
}
html .k-info-colored {
  color: #50607f;
  border-color: #d0d9df;
  background-color: #f0f9ff;
}
html .k-error-colored {
  color: #7f5050;
  border-color: #dfd0d0;
  background-color: #fff0f0;
}
.k-inline-block {
  padding: 0 2px;
}
/* loading */
.k-loading,
.k-loading-image {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
.k-loading-mask,
.k-loading-image,
.k-loading-text {
  position: absolute;
}
.k-loading-mask {
  z-index: 100;
}
.k-loading-mask .k-loading-progress {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.k-loading-text {
  text-indent: -4000px;
  text-align: center;
  /*rtl*/
}
.k-loading-image,
.k-loading-color {
  width: 100%;
  height: 100%;
}
.k-loading-image {
  top: 0;
  left: 0;
  z-index: 2;
}
.k-loading-color {
  filter: alpha(opacity=30);
  opacity: .3;
}
.k-content-frame {
  border: 0;
  width: 100%;
  height: 100%;
}
.k-pane > .k-splitter-overlay {
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
}
/* drag n drop */
.k-drag-clue {
  position: absolute;
  z-index: 10003;
  border-style: solid;
  border-width: 1px;
  font-size: .9em;
  padding: .2em .4em;
  white-space: nowrap;
  cursor: default;
}
.k-drag-status {
  margin-top: -3px;
  margin-right: 4px;
  vertical-align: middle;
}
.k-reorder-cue {
  position: absolute;
  width: 1px;
  overflow: visible;
}
.k-reorder-cue .k-icon {
  position: absolute;
  left: -4px;
  width: 8px;
  height: 4px;
}
.k-reorder-cue .k-i-arrow-s {
  top: -4px;
  background-position: -4px -166px;
}
.k-reorder-cue .k-i-arrow-n {
  bottom: -4px;
  background-position: -4px -134px;
}
/* virtual scrollbar */
.k-scrollbar {
  position: absolute;
  overflow: scroll;
}
.k-scrollbar-vertical {
  top: 0;
  right: 0;
  width: 17px;
  /* scrollbar width */
  height: 100%;
  overflow-x: hidden;
}
.k-touch-scrollbar {
  display: none;
  position: absolute;
  z-index: 200000;
  height: 8px;
  width: 8px;
  border: 1px solid #8a8a8a;
  background-color: #858585;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  body .k-touch-scrollbar {
    height: 12px;
    width: 12px;
    border-radius: 7px;
  }
}
.k-virtual-scrollable-wrap {
  overflow-x: auto;
  /*needed by IE8*/
}
/* current time indicator */
.k-current-time {
  background: #f00;
  position: absolute;
}
/* override box sizing for grid layout framework integration (Bootstrap 3, Foundation 4) */
.k-animation-container,
.k-widget,
.k-widget *,
.k-animation-container *,
.k-widget *:before,
.k-animation-container *:after,
.k-block .k-header,
.k-list-container {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.k-button,
.k-textbox,
.k-autocomplete,
div.k-window-content,
.k-tabstrip > .k-content > .km-scroll-container,
.k-block,
.k-edit-cell .k-widget,
.k-grid-edit-row .k-widget,
.k-grid-edit-row .text-box,
.km-actionsheet > li,
.km-shim {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
/* Fix for Bootstrap 3 */
.input-group .form-control {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.form-control.k-widget {
  padding: 0;
}
a.k-button:hover {
  text-decoration: none;
}
/* override iOS styles in mobile Kendo */
.km-widget,
.km-widget * {
  -webkit-background-clip: border-box;
  background-clip: border-box;
}
input.k-checkbox,
.k-radio {
  display: inline;
  opacity: 0;
  width: 0;
  margin: 0;
  -webkit-appearance: none;
  overflow: hidden;
}
.k-ff input.k-checkbox,
.k-ff .k-radio {
  position: absolute;
}
.k-checkbox-label {
  position: relative;
  padding-left: 1.5em;
  vertical-align: middle;
  line-height: 0.875em;
  cursor: pointer;
}
.k-checkbox-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-width: 1px;
  border-style: solid;
  width: 1em;
  height: 1em;
  font-size: 1em;
  line-height: 1em;
  text-align: center;
}
.k-checkbox:indeterminate + .k-checkbox-label:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  border-width: 1px;
  border-style: solid;
  width: 8px;
  height: 8px;
  font-size: 1em;
  text-align: center;
  content: " ";
  margin-left: -2px;
}
.k-checkbox:checked + .k-checkbox-label:before {
  content: "\2713";
}
.k-checkbox:disabled + .k-checkbox-label {
  cursor: auto;
}
.k-radio-label {
  position: relative;
  padding-left: 1.5em;
  vertical-align: middle;
  line-height: 0.875em;
  cursor: pointer;
}
.k-radio-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  border-style: solid;
}
.k-radio:checked + .k-radio-label:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
}
.k-radio:disabled + .k-radio-label {
  cursor: auto;
}
.k-ie8 input.k-checkbox,
.k-ie8 .k-radio {
  display: inline-block;
  width: auto;
}
.k-ie8 .k-checkbox-label,
.k-ie8 .k-radio-label {
  padding-left: 0;
}
.k-ie8 .k-checkbox-label:before,
.k-ie8 .k-radio-label:before,
.k-ie8 .k-radio-label:after {
  display: none;
}
/* RTL for checkboxes and radio buttons */
.k-rtl .k-checkbox-label,
.k-rtl .k-radio-label {
  padding-right: 1.5em;
}
.k-rtl .k-checkbox-label:before,
.k-rtl .k-radio-label:before {
  right: 0;
}
.k-rtl .k-radio:checked + .k-radio-label:after {
  right: 3px;
}
input.k-checkbox + label {
  -webkit-user-select: none;
}
/* Off-screen container used during export */
.k-pdf-export-shadow {
  position: absolute;
  overflow: hidden;
  left: -15000px;
  width: 14400px;
}
.km-native-scroller {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: pan-x pan-y;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-scroll-snap-type: proximity;
}
/* responsive panel */
.k-rpanel-left {
  -webkit-transform: translateX(-100%) translateZ(0);
  -ms-transform: translateX(-100%) translateZ(0);
  transform: translateX(-100%) translateZ(0);
  left: 0;
}
.k-rpanel-right {
  -webkit-transform: translateX(100%) translateZ(0);
  -ms-transform: translateX(100%) translateZ(0);
  transform: translateX(100%) translateZ(0);
  right: 0;
}
.k-rpanel-left,
.k-rpanel-right {
  position: fixed;
  display: block;
  overflow: auto;
  min-width: 320px;
  height: 100%;
  top: 0;
}
.k-rpanel-left.k-rpanel-expanded,
.k-rpanel-right.k-rpanel-expanded {
  -webkit-transform: translateX(0) translateZ(0);
  -ms-transform: translateX(0) translateZ(0);
  transform: translateX(0) translateZ(0);
}
.k-rpanel-left + *,
.k-rpanel-right + * {
  overflow: auto;
}
.k-rpanel-top {
  position: static;
  max-height: 0;
}
.k-rpanel-top.k-rpanel-expanded {
  max-height: 568px;
  overflow: visible !important;
}
.k-edit-form {
  margin: 0;
  padding: 0;
}
.k-window > div.k-popup-edit-form {
  padding: 1em 0;
}
.k-grid-edit-row .k-edit-form td {
  border-bottom-width: 0;
}
.k-edit-form-container {
  position: relative;
  width: 400px;
}
.k-edit-label,
.k-edit-form-container .editor-label {
  float: left;
  clear: both;
  width: 30%;
  padding: .4em 0 1em;
  margin-left: 2%;
  text-align: right;
}
.k-edit-field,
.k-edit-form-container .editor-field {
  float: right;
  clear: right;
  width: 60%;
  margin-right: 2%;
  padding: 0 0 .6em;
}
.k-edit-field > input[type="checkbox"],
.k-edit-field > input[type="radio"] {
  margin-top: .4em;
}
.k-edit-form-container .k-button {
  margin: 0 .16em;
}
.k-edit-field > input[type="checkbox"]:first-child,
.k-edit-field > input[type="radio"]:first-child,
.k-edit-field > label:first-child > input[type="checkbox"],
.k-edit-field > .k-button:first-child {
  margin-left: 0;
}
.k-edit-form-container .k-edit-buttons {
  clear: both;
  text-align: right;
  border-width: 1px 0 0;
  border-style: solid;
  position: relative;
  bottom: -1em;
  padding: .6em;
}
/* Window */
div.k-window {
  display: inline-block;
  position: absolute;
  z-index: 10001;
  border-style: solid;
  border-width: 1px;
  padding-top: 2em;
}
.k-block > .k-header,
.k-window-titlebar {
  position: absolute;
  width: 100%;
  height: 1.1em;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-top: -2em;
  padding: .4em 0;
  font-size: 1.2em;
  white-space: nowrap;
  min-height: 16px;
  /* icon size */
}
.k-block > .k-header {
  position: relative;
  margin: -2px 0 10px -2px;
  padding: .3em 2px;
}
.k-window-title {
  position: absolute;
  left: .44em;
  right: .44em;
  overflow: hidden;
  cursor: default;
  text-overflow: ellipsis;
}
.k-window-title .k-image {
  margin: 0 5px 0 0;
  vertical-align: middle;
}
div.k-window-titleless {
  padding-top: 0;
}
div.k-window-content {
  position: relative;
  height: 100%;
  padding: .58em;
  overflow: auto;
  outline: 0;
}
div.k-window-iframecontent {
  padding: 0;
  overflow: visible;
}
.k-window-content > .km-scroll-container {
  height: 100%;
}
.k-window-titlebar .k-window-actions {
  position: absolute;
  top: 0;
  right: .3em;
  padding-top: .3em;
  white-space: nowrap;
}
.k-window-titlebar .k-window-action {
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 2px;
  text-decoration: none;
  vertical-align: middle;
  opacity: .7;
}
.k-window-titlebar .k-state-hover {
  border-style: solid;
  border-width: 1px;
  padding: 1px;
  opacity: 1;
}
.k-window-action .k-icon {
  margin: 0;
  vertical-align: top;
}
.k-window > .k-resize-handle {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  font-size: 0;
  line-height: 6px;
  filter: alpha(opacity=0);
  opacity: 0;
  zoom: 1;
}
.k-resize-n {
  top: -3px;
  left: 0;
  width: 100%;
  height: 6px;
  cursor: n-resize;
}
.k-resize-e {
  top: 0;
  right: -3px;
  width: 6px;
  height: 100%;
  cursor: e-resize;
}
.k-resize-s {
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 6px;
  cursor: s-resize;
}
.k-resize-w {
  top: 0;
  left: -3px;
  width: 6px;
  height: 100%;
  cursor: w-resize;
}
.k-resize-se {
  bottom: -3px;
  right: -3px;
  width: 16px;
  height: 16px;
  cursor: se-resize;
}
.k-resize-sw {
  bottom: -3px;
  left: -3px;
  width: 6px;
  height: 6px;
  cursor: sw-resize;
}
.k-resize-ne {
  top: -3px;
  right: -3px;
  width: 6px;
  height: 6px;
  cursor: ne-resize;
}
.k-resize-nw {
  top: -3px;
  left: -3px;
  width: 6px;
  height: 6px;
  cursor: nw-resize;
}
.k-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  width: 100%;
  height: 100%;
  background-color: #000;
  filter: alpha(opacity=50);
  opacity: .5;
}
.k-window .k-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  filter: alpha(opacity=0);
  opacity: 0;
}
.k-action-buttons {
  clear: both;
  text-align: right;
  border-width: 1px 0 0;
  border-style: solid;
  position: relative;
  bottom: -1em;
  padding: .6em;
  margin: 0 -1em;
}
.k-action-buttons .k-button {
  display: inline-block;
  margin: 0 0 0 6px;
  min-width: 75px;
}
/* TabStrip */
.k-tabstrip {
  margin: 0;
  padding: 0;
  zoom: 1;
  position: relative;
}
.k-tabstrip-items {
  padding: 0.3em 0.3em 0;
}
.k-tabstrip-scrollable .k-tabstrip-items {
  white-space: nowrap;
  overflow: hidden;
}
.k-tabstrip > .k-button {
  position: absolute;
  top: .4em;
  z-index: 2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.k-tabstrip-bottom > .k-button {
  top: auto;
  bottom: .4em;
}
.k-tabstrip-prev {
  left: .4em;
}
.k-tabstrip-next {
  right: .4em;
}
.k-tabstrip-items .k-item,
.k-panelbar .k-tabstrip-items .k-item {
  list-style-type: none;
  display: inline-block;
  position: relative;
  border-style: solid;
  border-width: 1px 1px 0;
  margin: 0 -1px 0 0;
  padding: 0;
  vertical-align: top;
}
.k-tabstrip-items .k-tab-on-top,
.k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-items .k-state-active {
  margin-bottom: -1px;
  padding-bottom: 1px;
}
.k-tabstrip-items .k-tab-on-top {
  z-index: 1;
}
.k-tabstrip-items .k-link,
.k-panelbar .k-tabstrip-items .k-link {
  display: inline-block;
  border-bottom-width: 0;
  padding: .5em .92em;
}
.k-tabstrip-items .k-icon,
.k-panelbar .k-tabstrip-items .k-icon {
  margin: -1px 4px 0 -3px;
  vertical-align: top;
}
.k-tabstrip-items .k-item .k-image,
.k-tabstrip-items .k-item .k-sprite,
.k-panelbar .k-tabstrip-items .k-item .k-image,
.k-panelbar .k-tabstrip-items .k-item .k-sprite {
  margin: -3px 3px 0 -6px;
  vertical-align: middle;
}
/* TabStrip Loading Progress */
.k-tabstrip-items .k-loading {
  top: 0;
  left: 0;
  height: 0;
  width: 20%;
  position: absolute;
  background: transparent;
  border-top: 1px solid transparent;
  border-color: inherit;
  -webkit-transition: width 200ms linear;
          transition: width 200ms linear;
  -webkit-transition: "width 200ms linear";
          transition: "width 200ms linear";
  -webkit-animation: k-tab-loader 1s ease-in-out infinite;
          animation: k-tab-loader 1s ease-in-out infinite;
}
.k-tabstrip-items .k-progress {
  -webkit-animation: none;
          animation: none;
}
.k-tabstrip-items .k-loading.k-complete {
  width: 100%;
  -webkit-animation: none;
          animation: none;
}
.k-tabstrip > .k-content,
.k-panelbar .k-tabstrip > .k-content {
  position: static;
  border-style: solid;
  border-width: 1px;
  margin: 0 .286em .3em;
  padding: .3em .92em;
  zoom: 1;
}
.k-tabstrip > .k-content {
  display: none;
  overflow: auto;
}
.k-tabstrip > .k-content.km-scroll-wrapper {
  padding: 0;
}
.k-tabstrip > .k-content > .km-scroll-container {
  padding: .3em .92em;
}
@-webkit-keyframes k-tab-loader {
  0% {
    left: 0;
  }
  50% {
    left: 80%;
  }
  100% {
    left: 0;
  }
}
@keyframes k-tab-loader {
  0% {
    left: 0;
  }
  50% {
    left: 80%;
  }
  100% {
    left: 0;
  }
}
/* left and right tabs */
.k-tabstrip-left > div.k-content,
.k-tabstrip-right > div.k-content {
  margin: .286em .3em;
}
.k-tabstrip-left > .k-tabstrip-items .k-item,
.k-tabstrip-right > .k-tabstrip-items .k-item {
  display: block;
  margin-bottom: -1px;
}
.k-tabstrip-left > .k-tabstrip-items .k-link,
.k-tabstrip-right > .k-tabstrip-items .k-link {
  display: block;
}
.k-tabstrip-left > .k-tabstrip-items .k-tab-on-top,
.k-tabstrip-right > .k-tabstrip-items .k-tab-on-top,
.k-tabstrip-left > .k-tabstrip-items .k-state-active,
.k-tabstrip-right > .k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-left > .k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-right > .k-tabstrip-items .k-state-active {
  margin-bottom: -1px;
  padding-bottom: 0;
}
/* left tabs */
.k-tabstrip-left > .k-tabstrip-items {
  float: left;
  padding: .25em 0 .3em .3em;
}
.k-tabstrip-left > .k-tabstrip-items .k-item {
  border-width: 1px 0 1px 1px;
  border-radius: 3px 0 0 3px;
}
.k-tabstrip-left > .k-tabstrip-items .k-state-active {
  border-width: 1px 0 1px 1px;
}
.k-tabstrip-left > .k-tabstrip-items .k-tab-on-top,
.k-tabstrip-left > .k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-left > .k-tabstrip-items .k-state-active {
  margin-right: -2px;
  padding-right: 1px;
}
/* right tabs */
.k-tabstrip-right > .k-tabstrip-items {
  float: right;
  padding: .25em .3em .3em 0;
}
.k-tabstrip-right > .k-tabstrip-items .k-item {
  border-width: 1px 1px 1px 0;
  border-radius: 0 3px 3px 0;
}
.k-tabstrip-right > .k-tabstrip-items .k-state-active {
  border-width: 1px 1px 1px 0;
}
.k-tabstrip-right > .k-tabstrip-items .k-tab-on-top,
.k-tabstrip-right > .k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-right > .k-tabstrip-items .k-state-active {
  margin-left: -1px;
  padding-left: 1px;
}
/* bottom tabs */
.k-tabstrip-bottom > .k-tabstrip-items {
  margin-top: -1px;
  padding: 0 .3em .3em;
}
.k-tabstrip-bottom > .k-content,
.k-panelbar .k-tabstrip-bottom > .k-content {
  margin: .3em .286em 0;
  z-index: 1;
  position: relative;
}
.k-tabstrip-bottom > .k-tabstrip-items .k-item {
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;
}
.k-tabstrip-bottom > .k-tabstrip-items .k-state-active {
  margin-bottom: 0;
  padding-bottom: 0;
}
.k-tabstrip-bottom > .k-content {
  min-height: 100px;
}
.k-tabstrip-bottom > .k-tabstrip-items .k-loading {
  top: auto;
  bottom: 0;
}
/* PanelBar */
.k-panelbar {
  zoom: 1;
}
.k-panelbar > .k-item,
.k-panel > .k-item {
  list-style-type: none;
  display: block;
  border-width: 0;
  margin: 0;
  zoom: 1;
  border-radius: 0;
}
.k-panelbar .k-link > .k-image,
.k-panelbar .k-link > .k-sprite {
  float: left;
  margin-top: 4px;
  margin-right: 5px;
  vertical-align: middle;
}
.k-panelbar > .k-item > .k-link,
.k-panel > .k-item > .k-link {
  display: block;
  position: relative;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 0 1em;
  line-height: 2.34em;
  text-decoration: none;
  zoom: 1;
}
.k-panelbar-expand,
.k-panelbar-collapse {
  position: absolute;
  top: 50%;
  right: 4px;
  margin-top: -8px;
}
.k-panelbar .k-panel,
.k-panelbar .k-content {
  position: relative;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin: 0;
  padding: 0;
  zoom: 1;
}
.k-panel > .k-item > .k-link {
  border-bottom: 0;
  font-size: .95em;
  line-height: 2.2;
}
.k-panel .k-panel > .k-item > .k-link {
  padding-left: 2em;
}
.k-panelbar .k-i-seek-e .k-link {
  border-bottom: 0;
}
.k-panel .k-panel {
  border-bottom: 0;
}
/* Menu */
.k-menu {
  cursor: default;
}
.k-menu,
.k-menu .k-menu-group {
  list-style: none;
  margin: 0;
  padding: 0;
  zoom: 1;
}
.k-menu:after {
  content: '';
  display: block;
  width: 99%;
  height: 0;
  float: inherit;
  clear: both;
}
.k-menu .k-item {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
      user-select: none;
}
.k-menu .k-item div {
  -webkit-user-select: default;
     -moz-user-select: default;
      -ms-user-select: default;
          user-select: default;
}
.k-menu .k-item .k-item,
ul.k-menu-vertical > .k-item {
  display: block;
  float: none;
  border-width: 0;
}
.k-menu .k-item > .k-link > .k-icon,
.k-menu .k-image,
.k-menu .k-sprite {
  margin: -2px 4px 0 -4px;
  vertical-align: middle;
}
.k-menu .k-item > .k-link > .k-icon {
  margin: -2px 0 0;
}
.k-menu .k-item > .k-link {
  display: block;
  padding: 1.2em 1.2em 1.1em;
  line-height: 1.34em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.k-menu .k-menu-group {
  display: none;
  border-style: solid;
  border-width: 1px;
  overflow: visible;
  white-space: nowrap;
}
.k-menu .k-menu-group > .k-item {
  display: block;
  border-width: 0;
}
.k-menu .k-item,
.k-widget.k-menu-horizontal > .k-item {
  position: relative;
  float: left;
  border-style: solid;
  border-width: 0 1px 0 0;
  vertical-align: top;
  zoom: 1;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.k-context-menu.k-menu-vertical > .k-item > .k-link,
.k-menu .k-menu-group .k-item > .k-link {
  padding: .28em 1.8em .38em .9em;
}
.k-context-menu.k-menu-horizontal > .k-separator {
  display: none;
}
.k-context-menu.k-menu-horizontal > .k-item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.k-context-menu.k-menu-horizontal > .k-last {
  border: 0;
}
.k-menu .k-item > .k-link > .k-i-arrow-s {
  margin-right: -8px;
}
.k-menu .k-item > .k-link > .k-i-arrow-e {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 2px;
  right: .2rem;
}
.k-menu .k-animation-container {
  border: 0;
}
.k-menu .k-animation-container,
.k-menu .k-menu-group {
  position: absolute;
  left: 0;
}
.k-menu .k-animation-container .k-animation-container,
.k-menu .k-menu-group .k-menu-group,
.k-menu-vertical .k-animation-container,
.k-menu-vertical .k-menu-group {
  top: 0;
  left: 0;
}
.k-menu .k-animation-container .k-menu-group {
  top: auto;
  left: auto;
  margin-left: -1px;
}
.k-menu .k-animation-container,
.k-popup .k-animation-container {
  margin-top: -1px;
  padding-left: 1px;
}
.k-ie .k-menu .k-animation-container,
.k-ie .k-popup .k-animation-container {
  margin-top: -2px;
}
.k-popup .k-animation-container .k-popup {
  margin-left: -1px;
}
ul.k-menu .k-separator {
  padding: 0.25em 0;
  height: 100%;
  width: 1px;
  font-size: 0;
  line-height: 0;
  border-width: 0 1px 0 0;
}
ul.k-menu-vertical .k-separator,
.k-menu .k-menu-group .k-separator {
  padding: 0;
  height: 1px;
  width: 100%;
  border-width: 1px 0 0;
}
/* Context Menu */
.k-context-menu {
  border: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/* Calendar */
.k-calendar {
  position: relative;
  display: inline-block;
  width: 16.917em;
  overflow: hidden;
}
.k-calendar td,
.k-calendar .k-link {
  text-decoration: none;
}
.k-calendar .k-action-link {
  text-decoration: underline;
}
.k-calendar .k-header,
.k-calendar .k-footer {
  position: relative;
  text-align: center;
  zoom: 1;
}
.k-widget.k-calendar .k-nav-prev,
.k-widget.k-calendar .k-nav-next {
  position: absolute;
  top: 0.16666em;
  line-height: 1.8333em;
  height: 1.8333em;
}
.k-widget.k-calendar .k-nav-prev {
  left: 1%;
}
.k-widget.k-calendar .k-nav-next {
  right: 1%;
}
.k-calendar .k-content {
  float: left;
  border-spacing: 0;
  width: 100%;
  height: 14.167em;
  border-width: 0;
  margin: 0;
  table-layout: fixed;
  outline: 0;
}
.k-calendar .k-content,
.k-calendar .k-content th {
  text-align: right;
}
.k-calendar .k-animation-container .k-content {
  height: 100%;
}
.k-widget.k-calendar .k-nav-fast {
  display: inline-block;
  width: 75%;
  height: 1.8333em;
  line-height: 1.8333em;
  margin: 0.16666em -0.08333em 0.3333em 0;
}
.k-calendar .k-header .k-icon {
  vertical-align: middle;
}
.k-calendar .k-header .k-link.k-nav-prev,
.k-calendar .k-header .k-link.k-nav-next {
  height: 1.8333em;
  width: 1.8333em;
}
.k-calendar th {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: .4em .45em .4em .1em;
  font-weight: normal;
  cursor: default;
}
.k-calendar td {
  padding: 0.08333em;
  cursor: pointer;
}
.k-calendar .k-state-focus {
  border-style: dotted;
  border-width: 0.08333em;
  padding: 0;
}
.k-calendar .k-content .k-link {
  display: block;
  overflow: hidden;
  min-height: 1.8333em;
  line-height: 1.8333em;
  padding: 0 .45em 0 .1em;
}
.k-calendar .k-meta-view .k-link {
  padding: .25em 0 .3em;
  text-align: center;
}
.k-calendar .k-footer {
  clear: both;
}
.k-calendar .k-footer .k-nav-today,
.k-calendar .k-footer > .k-state-disabled {
  display: block;
  height: 100%;
  padding: .5em 0;
}
.k-calendar .k-nav-today:hover {
  text-decoration: underline;
}
/* ComboBox & DropDownList */
span.k-datepicker,
span.k-timepicker,
span.k-datetimepicker,
span.k-colorpicker,
span.k-numerictextbox,
span.k-combobox,
span.k-dropdown,
.k-toolbar .k-split-button {
  background-image: none;
}
.k-autocomplete,
.k-combobox,
.k-datepicker,
.k-timepicker,
.k-datetimepicker,
.k-colorpicker,
.k-numerictextbox,
.k-dropdown,
.k-selectbox,
.k-textbox,
.k-toolbar .k-split-button {
  position: relative;
  display: inline-block;
  width: 12.4em;
  overflow: visible;
  border-width: 0;
  vertical-align: middle;
}
.k-filter-menu .k-combobox,
.k-filter-menu .k-datepicker,
.k-filter-menu .k-timepicker,
.k-filter-menu .k-datetimepicker,
.k-filter-menu .k-numerictextbox,
.k-filter-menu .k-dropdown,
.k-filter-menu .k-textbox {
  width: 13.2em;
}
.k-autocomplete,
.k-combobox,
.k-datepicker,
.k-timepicker,
.k-datetimepicker,
.k-colorpicker,
.k-numerictextbox,
.k-dropdown,
.k-selectbox,
.k-toolbar .k-split-button {
  white-space: nowrap;
}
.k-colorpicker,
.k-toolbar .k-split-button {
  width: auto;
}
.k-datetimepicker {
  width: 15em;
}
.k-autocomplete,
.k-picker-wrap,
.k-numeric-wrap {
  position: relative;
  cursor: default;
}
.k-dropdown-wrap {
  position: relative;
}
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap {
  display: block;
}
.k-block,
.k-widget,
.k-grid,
.k-slider,
.k-splitter,
.k-treeview,
.k-panelbar,
.k-content,
.k-header-column-menu {
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-block,
.k-slider,
.k-splitbar,
.k-calendar,
.k-treeview,
.k-pager-wrap,
.k-grid-header .k-link,
.k-header-column-menu {
  -webkit-touch-callout: none;
}
.k-list-scroller {
  position: relative;
  overflow: auto;
}
.k-popup.k-list-container,
.k-popup.k-calendar-container {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 2px;
  border-width: 1px;
  border-style: solid;
}
.k-list-container.k-state-border-down,
.k-autocomplete.k-state-border-down,
.k-dropdown-wrap.k-state-border-down,
.k-picker-wrap.k-state-border-down,
.k-numeric-wrap.k-state-border-down {
  border-bottom-width: 0;
  padding-bottom: 1px;
}
.k-list-container .km-scroll-container {
  padding-bottom: 6px;
}
.k-textbox,
.k-autocomplete,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap {
  border-width: 1px;
  border-style: solid;
  padding: 0 1.9em 0 0;
}
.k-numeric-wrap.k-expand-padding {
  padding-right: 0;
}
.k-textbox,
.k-autocomplete {
  padding: 0;
}
.k-textbox.k-space-left {
  padding-left: 1.9em;
}
.k-textbox.k-space-right {
  padding-right: 1.9em;
}
.k-textbox .k-icon {
  top: 50%;
  margin: -8px 0 0;
  position: absolute;
}
.k-space-left .k-icon {
  left: 3px;
}
.k-space-right .k-icon {
  right: 3px;
}
/*prevent missing bottom border at some zoom levels*/
span.k-textbox:after {
  content: "\a0";
  display: block;
  height: .4px;
  overflow: hidden;
}
.k-autocomplete,
.k-dropdown-wrap.k-state-focused,
.k-dropdown-wrap.k-state-hover,
.k-picker-wrap.k-state-focused,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-focused,
.k-numeric-wrap.k-state-hover {
  -webkit-transition: -webkit-box-shadow .15s ease-out;
          transition: box-shadow .15s ease-out;
  -webkit-transition: "box-shadow .15s ease-out";
          transition: "box-shadow .15s ease-out";
}
.k-textbox > input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-combobox .k-input {
  width: 100%;
  vertical-align: top;
}
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input {
  font-family: inherit;
  border-width: 0;
  outline: 0;
}
.k-dropdown .k-input,
.k-selectbox .k-input {
  background: transparent;
}
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-dropdown-wrap .k-select {
  position: absolute;
  /* icon positioning */
  top: 0;
  right: 0;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
}
.k-combobox .k-select,
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: inherit;
  /* skin-related, inherit does not work in ie7- */
}
span.k-datetimepicker .k-select,
span.k-datetimepicker .k-select + .k-select {
  right: 0;
}
.k-textbox > input,
.k-autocomplete .k-input {
  display: block;
}
.k-combobox .k-icon {
  /*margin-top: 1px;*/
}
.k-dropdown .k-select,
.k-selectbox .k-select {
  overflow: hidden;
  border: 0;
  text-decoration: none;
  font: inherit;
  color: inherit;
}
.k-dropdown .k-input,
.k-selectbox .k-input {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-textbox > input,
.k-autocomplete .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input {
  height: 1.94em;
  line-height: 1.94em;
  padding: 0.177em 0;
  text-indent: 0.8em;
  border: 0;
  margin: 0;
}
.k-combobox .k-dropdown-wrap:before,
.k-picker-wrap:before,
.k-numeric-wrap:before {
  content: "\a0";
  display: inline-block;
  width: 0;
  height: 1.94em;
  padding-bottom: 0.3em;
}
/* fix missing bottom border on browser zoom in Chrome */
.k-webkit .k-combobox .k-dropdown-wrap:before,
.k-webkit .k-picker-wrap:before,
.k-webkit .k-numeric-wrap:before {
  padding-bottom: 0.38em;
}
/* above style breaks NumericTextBox layout due display:block style applied to the input */
.km.root .k-combobox .k-dropdown-wrap:before,
.km.root .k-picker-wrap:before,
.km.root .k-numeric-wrap:before {
  content: none;
}
.k-combobox .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input {
  display: inline;
}
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-dropdown-wrap .k-select {
  min-height: 1.94em;
  line-height: 2.29em;
  vertical-align: middle;
  -moz-box-sizing: border-box;
  text-align: center;
  width: 1.9em;
  height: 100%;
}
.k-numeric-wrap .k-select {
  padding: 0;
}
body .k-datetimepicker .k-select {
  border-radius: 0;
}
.k-combobox .k-icon,
.k-dropdown,
.k-selectbox .k-icon {
  cursor: pointer;
}
.k-popup {
  border-style: solid;
  border-width: 1px;
}
.k-popup .k-item,
.k-list-optionlabel {
  cursor: default;
}
.k-popup .k-calendar {
  border: 0;
}
.k-list {
  height: auto;
}
.k-popup .k-list .k-item,
.k-fieldselector .k-list .k-item,
.k-list-optionlabel,
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  padding: 1px 5px 1px 5px;
  line-height: 1.8em;
  min-height: 1.8em;
}
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  padding-right: 22px;
}
.k-overflow-container .k-item {
  padding: 1px;
}
.k-overflow-container > .k-state-disabled .k-button,
.k-overflow-container .k-button.k-state-disabled,
.k-overflow-container .k-button.k-state-disabled:hover {
  border: 0 ;
  background: none;
}
.k-popup .k-list .k-state-hover,
.k-popup .k-list .k-state-focused,
.k-popup .k-list .k-state-selected,
.k-overflow-container .k-state-hover,
.k-overflow-container .k-state-focused,
.k-overflow-container .k-state-selected,
.k-fieldselector .k-list .k-item,
.k-list-optionlabel.k-state-focused,
.k-list-optionlabel.k-state-selected {
  padding: 0 4px;
  border-width: 1px;
  border-style: solid;
}
.k-list-filter {
  position: relative;
  margin-bottom: 2px;
}
.k-list-filter > .k-textbox {
  padding-right: 20px;
  width: 100%;
}
.k-list-filter > .k-icon {
  position: absolute;
  right: 6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.km-root .k-list-filter > .k-textbox {
  padding-left: 0;
  padding-right: 0;
  border-left-width: 0;
  border-right-width: 0;
}
/* MultiSelect */
.k-multiselect-wrap {
  position: relative;
  border-width: 0px;
  border-style: solid;
  border-radius: 4px;
  border-color: #C5C5C5;
  background-color: #FFF;
  min-height: 2.04em;
}
.k-multiselect-wrap .k-input {
  background-color: transparent;
  height: 1.31em;
  line-height: 1.31em;
  padding: 0.18em 0;
  text-indent: 0.8em;
  border: 0;
  margin: 1px 0 0;
  float: left;
}
.k-multiselect-wrap .k-input::-ms-clear {
  display: none;
}
.k-multiselect-wrap li {
  margin: 1px 0 1px 1px;
  padding: .1em 1.6em .1em .4em;
  line-height: 1.79em;
  float: left;
  position: relative;
}
.k-autocomplete .k-loading,
.k-multiselect .k-loading {
  position: absolute;
  right: 3px;
  bottom: 4px;
}
.k-multiselect .k-loading-hidden {
  visibility: hidden;
}
.k-multiselect-wrap .k-select {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: .1em .2em;
}
/* Date/Time Pickers */
.k-datetimepicker .k-picker-wrap {
  padding-right: 3.8em;
}
.k-datetimepicker .k-select {
  width: 3.8em;
}
.k-datetimepicker .k-picker-wrap .k-icon {
  margin: 0 2px;
}
.k-picker-wrap .k-icon {
  cursor: pointer;
}
.k-button,
.k-textbox,
.k-timepicker,
.k-datepicker,
.k-datetimepicker {
  display: inline-block;
  vertical-align: middle;
}
.k-picker-wrap .k-input {
  margin: 0;
}
.k-time-popup .k-item {
  padding: 1px 3px;
}
/* inputs */
.k-input {
  padding: 0.25em 0;
}
.k-input,
.k-textbox > input {
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-textbox {
  outline: 0;
}
input.k-textbox,
textarea.k-textbox {
  padding: 2px .3em;
}
input.k-textbox {
  height: 2.42em;
  text-indent: 0.8em;
  line-height: 1.6em;
}
.k-ie input.k-textbox {
  text-indent: 0.4em;
}
.k-ff input.k-textbox {
  height: 2.17em;
}
textarea.k-textbox {
  height: auto;
}
/* NumericTextBox */
span.k-numerictextbox {
  background-color: transparent;
}
.k-numerictextbox .k-input {
  margin: 0;
}
.k-numerictextbox .k-link {
  display: block;
  height: 1em;
  line-height: 1em;
  vertical-align: middle;
  border-width: 0;
  padding: 0;
}
.k-numerictextbox .k-icon {
  height: 11px;
}
.k-numeric-wrap .k-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* ColorPicker */
.k-colorpicker .k-picker-wrap {
  line-height: 2em;
}
.k-colorpicker .k-selected-color {
  vertical-align: top;
  line-height: 0;
  display: inline-block;
  height: 2em;
  width: 2em;
}
.k-colorpicker .k-tool-icon {
  position: relative;
  top: -2px;
  display: inline-block;
  padding: 3px 3px 2px;
  font-size: 0;
  line-height: 0;
  margin-right: 3px;
  margin-left: 2px;
  margin-bottom: 3px;
  background-repeat: no-repeat;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  -ms-high-contrast-adjust: none;
}
.k-colorpicker .k-tool-icon .k-selected-color {
  display: block;
  height: 3px;
  width: 16px;
  position: absolute;
  left: 3px;
  bottom: -3px;
  border-radius: 0 !important;
}
.k-colorpicker .k-select {
  cursor: pointer;
}
.k-disabled-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.k-colorpalette {
  position: relative;
  line-height: 0;
  border-width: 0;
  display: inline-block;
}
.k-colorpalette .k-palette {
  border-collapse: collapse;
  position: relative;
  width: 100%;
  height: 100%;
}
.k-colorpalette .k-item {
  width: 14px;
  height: 14px;
  overflow: hidden;
  -ms-high-contrast-adjust: none;
}
.k-colorpalette .k-item.k-state-selected,
.k-colorpalette .k-item.k-state-selected:hover {
  z-index: 100;
  background: transparent;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.7), inset 0 0 0 1px rgba(255, 255, 255, 0.45);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.7), inset 0 0 0 1px rgba(255, 255, 255, 0.45);
  position: relative;
}
.k-colorpalette .k-item:hover {
  z-index: 101;
  position: relative;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.3);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.3);
}
.k-flatcolorpicker {
  position: relative;
  display: inline-block;
  width: 250px;
  padding-bottom: 5px;
}
div.k-flatcolorpicker {
  background-color: transparent;
  background-image: none;
}
.k-flatcolorpicker .k-selected-color {
  background-image: url("textures/transtexture.png");
  background-position: 50% 50%;
  text-align: right;
}
.k-flatcolorpicker .k-selected-color input.k-color-value {
  font-family: Consolas, "Ubuntu Mono", "Lucida Console", "Courier New", monospace;
  padding: .75em .3em .65em 1em;
  border: 0;
  margin: 0;
  width: 70%;
}
.k-flatcolorpicker .k-hsv-rectangle {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-touch-action: pinch-zoom double-tap-zoom;
}
.k-flatcolorpicker .k-hsv-rectangle .k-draghandle {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  border: 1px solid #eee;
  margin-left: -5px;
  margin-top: -5px;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 2px #444;
          box-shadow: 0 1px 2px #444;
  background: transparent;
}
.k-flatcolorpicker .k-hsv-rectangle .k-draghandle:hover,
.k-flatcolorpicker .k-hsv-rectangle .k-draghandle:focus {
  background: transparent;
  border-color: #fff;
  -webkit-box-shadow: 0 1px 5px #000;
          box-shadow: 0 1px 5px #000;
}
.k-flatcolorpicker .k-hsv-rectangle.k-dragging,
.k-flatcolorpicker .k-hsv-rectangle.k-dragging * {
  cursor: none;
}
.k-flatcolorpicker .k-slider-horizontal {
  height: 20px;
  width: 90%;
  margin: 0 5%;
}
.k-flatcolorpicker .k-slider-horizontal .k-slider-track {
  -webkit-box-shadow: 0 1px 0 #fff, 0 -1px 0 #999;
          box-shadow: 0 1px 0 #fff, 0 -1px 0 #999;
}
.k-flatcolorpicker .k-hue-slider,
.k-flatcolorpicker .k-transparency-slider {
  display: block;
}
.k-flatcolorpicker .k-hue-slider .k-slider-selection,
.k-flatcolorpicker .k-transparency-slider .k-slider-selection {
  background: transparent;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle,
.k-flatcolorpicker .k-transparency-slider .k-draghandle {
  background: transparent;
  border: 3px solid #eee;
  margin-top: 1px;
  height: 8px;
  width: 8px;
  -webkit-box-shadow: 0 1px 4px #444;
          box-shadow: 0 1px 4px #444;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle:hover,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:hover,
.k-flatcolorpicker .k-hue-slider .k-draghandle:focus,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:focus {
  background: transparent;
  border-color: #fff;
  -webkit-box-shadow: 0 1px 5px #000;
          box-shadow: 0 1px 5px #000;
  border-width: 2px;
  padding: 1px;
}
.k-flatcolorpicker .k-hue-slider .k-slider-track {
  background: -webkit-gradient(linear, left top, right top, from(#ff0000), color-stop(16%, #ffff00), color-stop(33%, #00ff00), color-stop(50%, #00ffff), color-stop(67%, #0000ff), color-stop(84%, #ff00ff), to(#ff0004));
  background: -webkit-linear-gradient(left, #ff0000 0%, #ffff00 16%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 84%, #ff0004 100%);
  background: linear-gradient(to right, #ff0000 0%, #ffff00 16%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 84%, #ff0004 100%);
}
.k-flatcolorpicker .k-transparency-slider .k-slider-track {
  background-image: url("textures/transparency.png");
  -webkit-background-size: 100% auto;
          background-size: 100% auto;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.k-flatcolorpicker .k-controls {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 90%;
}
.k-flatcolorpicker .k-controls .k-button {
  width: 6em;
}
.k-flatcolorpicker .k-hsv-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000)), -webkit-gradient(linear, left top, right top, from(#ffffff), to(rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%), -webkit-linear-gradient(left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%), linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  height: 180px;
  margin-bottom: 5px;
}
.k-ie9 .k-flatcolorpicker .k-hue-slider .k-slider-track {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjE2JSIgc3RvcC1jb2xvcj0iI2ZmZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjMzJSIgc3RvcC1jb2xvcj0iIzAwZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzAwZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY3JSIgc3RvcC1jb2xvcj0iIzAwMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijg0JSIgc3RvcC1jb2xvcj0iI2ZmMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjAwMDQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
}
.k-ie9 .k-flatcolorpicker .k-hsv-gradient {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+), url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
}
.k-ie8 .k-flatcolorpicker .k-hue-slider .k-slider-track {
  background: url("textures/hue.png") repeat 0 50%;
}
.k-ie8 .k-flatcolorpicker .k-transparency-slider .k-slider-track {
  background: url("textures/transparency.png") repeat 0 50%;
}
.k-ie8 .k-flatcolorpicker .k-hsv-gradient {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff',endColorstr='#00ffffff',GradientType=1) progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#ff000000',GradientType=0);
}
/* Notification */
.k-notification-wrap {
  padding: .6em .5em;
  cursor: default;
  position: relative;
  white-space: nowrap;
}
.k-notification-button .k-notification-wrap {
  padding-right: 20px;
}
.k-notification-wrap > .k-i-note {
  vertical-align: text-bottom;
  margin-right: 4px;
}
.k-notification-wrap > .k-i-close {
  position: absolute;
  top: 7px;
  right: 4px;
  display: none;
}
.k-notification-button .k-notification-wrap > .k-i-close {
  display: block;
}
/* Progressbar */
.k-progressbar {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.k-progressbar {
  border-radius: 4px;
}
.k-progressbar-horizontal {
  width: 27em;
  height: 1.9em;
}
.k-progressbar-vertical {
  width: 1.9em;
  height: 27em;
}
.k-progressbar > .k-state-selected {
  position: absolute;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
}
.k-progressbar-horizontal > .k-state-selected,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected {
  left: -1px;
  right: auto;
  top: -1px;
  height: 100%;
  border-radius: 4px 0 0 4px;
}
.k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected,
.k-rtl .k-progressbar-horizontal > .k-state-selected {
  left: auto;
  right: -1px;
  border-radius: 0 4px 4px 0;
}
.k-progressbar-vertical > .k-state-selected {
  left: -1px;
  bottom: -1px;
  width: 100%;
  border-radius: 0 0 4px 4px;
}
.k-progressbar-vertical.k-progressbar-reverse > .k-state-selected {
  bottom: auto;
  top: -1px;
  border-radius: 4px 4px 0 0;
}
.k-progressbar > .k-state-selected.k-complete,
.k-rtl .k-progressbar > .k-state-selected.k-complete {
  border-radius: 4px;
}
.k-progressbar > .k-reset {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: -1px;
  top: -1px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  white-space: nowrap;
}
.k-progressbar-horizontal .k-item {
  display: inline-block;
  height: 100%;
  border-style: solid;
  margin-left: -1px;
}
.k-progressbar-horizontal .k-item.k-first {
  margin-left: 0;
}
.k-progressbar-horizontal .k-item.k-last {
  border-right-width: 0;
}
.k-progressbar-horizontal .k-item,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-item {
  border-width: 1px 0 1px 1px;
}
.k-progressbar-horizontal.k-progressbar-reverse .k-item,
.k-rtl .k-progressbar-horizontal .k-item {
  border-width: 1px 0 1px 1px;
}
.k-progressbar-horizontal .k-first,
.k-rtl .k-progressbar-horizontal .k-last,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-last {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}
.k-progressbar-horizontal .k-last,
.k-rtl .k-progressbar-horizontal .k-first {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.k-progressbar-horizontal.k-progressbar-reverse .k-last,
.k-rtl .k-progressbar-horizontal .k-first {
  border-right-width: 1px;
}
.k-progressbar-horizontal .k-last.k-state-selected {
  border-right-width: 1px;
}
.k-progressbar-vertical .k-item {
  width: 100%;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  margin-top: -1px;
}
.k-progressbar-vertical .k-item.k-first {
  margin-top: 0;
}
.k-progressbar-vertical li.k-item.k-last {
  border-bottom-width: 0;
}
.k-progressbar-vertical .k-first {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.k-progressbar-vertical .k-last {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-width: 1px;
}
.k-progressbar-vertical.k-progressbar-reverse .k-item {
  border-width: 0 1px 1px 1px;
}
.k-progressbar-vertical.k-progressbar-reverse .k-first {
  border-top-width: 1px;
}
.k-progress-status-wrap {
  position: absolute;
  top: -1px;
  border: 1px solid transparent;
  line-height: 2em;
  width: 100%;
  height: 100%;
}
.k-progress-status-wrap,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-progress-status-wrap {
  left: -1px;
  right: auto;
  text-align: right;
}
.k-progressbar-horizontal.k-progressbar-reverse .k-progress-status-wrap,
.k-rtl .k-progressbar-horizontal .k-progress-status-wrap {
  left: auto;
  right: -1px;
  text-align: left;
}
.k-progressbar-vertical .k-progress-status-wrap {
  top: auto;
  bottom: -1px;
}
.k-progressbar-vertical.k-progressbar-reverse .k-progress-status-wrap {
  bottom: auto;
  top: -1px;
}
.k-progress-status {
  display: inline-block;
  padding: 0 .5em;
  min-width: 10px;
  white-space: nowrap;
}
.k-progressbar-vertical.k-progressbar-reverse .k-progress-status {
  position: absolute;
  bottom: 0;
  left: 0;
}
.k-progressbar-vertical .k-progress-status {
  -webkit-transform: rotate(-90deg) translateX(-100%);
      -ms-transform: rotate(-90deg) translateX(-100%);
          transform: rotate(-90deg) translateX(-100%);
  -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
          transform-origin: 0 0;
}
.k-progressbar-vertical.k-progressbar-reverse .k-progress-status {
  -webkit-transform: rotate(90deg) translateX(-100%);
      -ms-transform: rotate(90deg) translateX(-100%);
          transform: rotate(90deg) translateX(-100%);
  -webkit-transform-origin: 0 100%;
      -ms-transform-origin: 0 100%;
          transform-origin: 0 100%;
}
.k-ie8 .k-progressbar-vertical .k-progress-status {
  -webkit-writing-mode: bt-lr;
      -ms-writing-mode: bt-lr;
          writing-mode: bt-lr;
  padding: .5em 0;
}
/* Slider */
div.k-slider {
  position: relative;
  border-width: 0;
  background-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.k-slider-vertical {
  width: 26px;
  height: 200px;
  /* default height */
}
.k-slider-horizontal {
  display: inline-block;
  width: 200px;
  /* default width */
  height: 26px;
}
.k-slider-wrap {
  width: 100%;
  height: 100%;
}
.k-slider .k-button,
.k-grid .k-slider .k-button {
  position: absolute;
  top: 0;
  width: 24px;
  min-width: 0;
  height: 24px;
  margin: 0;
  padding: 0;
  outline: 0;
}
.k-slider .k-button .k-icon {
  margin-top: 3px;
  vertical-align: top;
}
.k-state-disabled .k-slider-wrap {
  filter: alpha(opacity=60);
  opacity: .6;
}
.k-state-disabled .k-slider-wrap .k-slider-items {
  color: #333;
}
.k-slider .k-button-decrease {
  left: 0;
}
.k-slider-vertical .k-button-decrease,
.k-grid .k-slider-vertical .k-button-decrease {
  top: auto;
  bottom: 0;
}
.k-slider .k-button-increase {
  right: 0;
}
.k-slider .k-icon,
.k-slider-track,
.k-slider .k-tick {
  cursor: pointer;
}
.k-slider-track,
.k-slider-selection {
  position: absolute;
  margin: 0;
  padding: 0;
}
.k-slider-horizontal .k-slider-track,
.k-slider-horizontal .k-slider-selection {
  top: 50%;
  left: 0;
  height: 8px;
  margin-top: -4px;
  background-repeat: repeat-x;
}
.k-slider-horizontal .k-slider-buttons .k-slider-track {
  left: 34px;
}
.k-slider-vertical .k-slider-track,
.k-slider-vertical .k-slider-selection {
  left: 50%;
  bottom: 0;
  width: 8px;
  margin-left: -4px;
  background-repeat: repeat-y;
}
.k-slider-vertical .k-slider-buttons .k-slider-track {
  bottom: 34px;
}
.k-draghandle {
  position: absolute;
  background-repeat: no-repeat;
  background-color: transparent;
  text-indent: -3333px;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  outline: 0;
}
.k-slider-horizontal .k-draghandle {
  top: -4px;
  width: 13px;
  height: 14px;
}
.k-slider-vertical .k-draghandle {
  left: -4px;
  width: 14px;
  height: 13px;
}
.k-slider-buttons .k-slider-items {
  margin-left: 34px;
}
.k-slider-horizontal .k-slider-items {
  height: 100%;
}
.k-slider-vertical .k-slider-items {
  padding-top: 1px;
}
.k-slider-vertical .k-slider-buttons .k-slider-items {
  padding-top: 0;
}
.k-slider-vertical .k-slider-buttons .k-slider-items {
  margin: 0;
  padding-top: 35px;
}
.k-slider .k-tick {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
.k-slider-horizontal .k-tick {
  float: left;
  height: 100%;
  text-align: center;
}
.k-slider-horizontal .k-tick {
  background-position: center -92px;
}
.k-slider-horizontal .k-slider-topleft .k-tick {
  background-position: center -122px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick {
  background-position: center -152px;
}
.k-slider-horizontal .k-tick-large {
  background-position: center -2px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large {
  background-position: center -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large {
  background-position: center -62px;
}
.k-slider-vertical .k-tick {
  background-position: -92px center;
}
.k-slider-vertical .k-slider-topleft .k-tick {
  background-position: -122px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick {
  background-position: -152px center;
}
.k-slider-vertical .k-tick-large {
  background-position: -2px center;
}
.k-slider-vertical .k-slider-topleft .k-tick-large {
  background-position: -32px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large {
  background-position: -62px center;
}
.k-slider-horizontal .k-first {
  background-position: 0 -92px;
}
.k-slider-horizontal .k-tick-large.k-first {
  background-position: 0 -2px;
}
.k-slider-horizontal .k-slider-topleft .k-first {
  background-position: 0 -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-first {
  background-position: 0 -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-first {
  background-position: 0 -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-first {
  background-position: 0 -62px;
}
.k-slider-horizontal .k-last {
  background-position: 100% -92px;
}
.k-slider-horizontal .k-tick-large.k-last {
  background-position: 100% -2px;
}
.k-slider-horizontal .k-slider-topleft .k-last {
  background-position: 100% -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-last {
  background-position: 100% -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-last {
  background-position: 100% -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-last {
  background-position: 100% -62px;
}
.k-slider-vertical .k-first {
  background-position: -92px 100%;
}
.k-slider-vertical .k-tick-large.k-first {
  background-position: -2px 100%;
}
.k-slider-vertical .k-slider-topleft .k-first {
  background-position: -122px 100%;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-first {
  background-position: -32px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-first {
  background-position: -152px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-first {
  background-position: -62px 100%;
}
.k-slider-vertical .k-last {
  background-position: -92px 0;
}
.k-slider-vertical .k-tick-large.k-last {
  background-position: -2px 0;
}
.k-slider-vertical .k-slider-topleft .k-last {
  background-position: -122px 0;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-last {
  background-position: -32px 0;
}
.k-slider-vertical .k-slider-bottomright .k-last {
  background-position: -152px 0;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-last {
  background-position: -62px 0;
}
.k-slider-vertical .k-tick {
  text-align: right;
}
.k-slider-vertical .k-slider-topleft .k-tick {
  text-align: left;
}
.k-slider .k-label {
  position: absolute;
  white-space: nowrap;
  font-size: .92em;
}
.k-slider-horizontal .k-label {
  left: 0;
  width: 100%;
  line-height: 1;
}
.k-slider-horizontal .k-first .k-label {
  left: -50%;
}
.k-slider-horizontal .k-last .k-label {
  left: auto;
  right: -50%;
}
.k-slider-horizontal .k-label {
  bottom: -1.2em;
}
.k-slider-horizontal .k-slider-topleft .k-label {
  top: -1.2em;
}
.k-slider-vertical .k-label {
  left: 120%;
  display: block;
  text-align: left;
}
.k-slider-vertical .k-last .k-label {
  top: -0.5em;
}
.k-slider-vertical .k-first .k-label {
  bottom: -0.5em;
}
.k-slider-vertical .k-slider-topleft .k-label {
  left: auto;
  right: 120%;
}
.k-slider-tooltip {
  top: -4444px;
  /*prevent window resize in IE8 when appending*/
}
/* Tooltip */
.k-tooltip {
  position: absolute;
  z-index: 12000;
  border-style: solid;
  border-width: 0;
  padding: 5px 5px 5px 6px;
  background-repeat: repeat-x;
  min-width: 20px;
  /*slider tooltip only*/
  text-align: center;
  /*slider tooltip only*/
}
.k-tooltip-button {
  text-align: right;
  height: 0;
}
.k-tooltip-content {
  height: 100%;
}
.k-tooltip-closable .k-tooltip-content {
  padding-right: 20px;
}
span.k-tooltip {
  position: static;
  display: inline-block;
  border-width: 1px;
  padding: 2px 5px 1px 6px;
}
.k-invalid-msg {
  display: none;
}
.k-callout {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px;
  border-color: transparent;
}
.k-callout-n {
  top: -12px;
  left: 50%;
}
.k-callout-w {
  top: 50%;
  left: -12px;
}
.k-callout-s {
  left: 50%;
  bottom: -12px;
}
.k-callout-e {
  top: 50%;
  right: -12px;
}
.k-slider-tooltip .k-callout-n,
.k-slider-tooltip .k-callout-s {
  margin-left: -6px;
}
.k-slider-tooltip .k-callout-w,
.k-slider-tooltip .k-callout-e {
  margin-top: -6px;
}
.k-tooltip-validation .k-warning {
  vertical-align: text-top;
  margin-right: 3px;
}
.k-tooltip-validation {
  z-index: 9999;
}
/* Toolbar */
.k-toolbar {
  position: relative;
  display: block;
  vertical-align: middle;
  line-height: 2.9em;
}
.k-toolbar .k-button .k-icon,
.k-toolbar .k-button .k-sprite,
.k-overflow-container .k-button .k-icon,
.k-overflow-container .k-button .k-sprite {
  vertical-align: middle;
  margin-top: -7px;
  margin-bottom: -5px;
}
.k-toolbar .k-input {
  line-height: inherit;
  height: inherit;
  padding-top: 2px;
  padding-bottom: 2px;
}
.k-toolbar .k-input:before {
  content: "\a0";
  display: inline-block;
  width: 0;
}
.k-ie .k-toolbar .k-input {
  height: 1.65em;
}
.k-toolbar .k-combobox .k-dropdown-wrap:before,
.k-toolbar .k-picker-wrap:before,
.k-toolbar .k-numeric-wrap:before {
  display: none;
}
.k-overflow-container .k-sprite {
  margin-left: -4px;
}
.k-toolbar-resizable {
  overflow: hidden;
  white-space: nowrap;
}
.k-toolbar > .k-align-left {
  float: none;
}
.k-toolbar > .k-align-right {
  float: right;
}
.k-toolbar > *,
.k-toolbar .k-button {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.72em;
}
.k-toolbar .k-separator {
  border-width: 0 0 0 1px;
  border-style: solid;
  width: 1px;
  line-height: inherit;
}
.k-toolbar .k-button-group {
  list-style-type: none;
}
.k-toolbar .k-button-group > li {
  display: inline-block;
}
.k-toolbar .k-button-group .k-button {
  margin: 0 0 0 -1px;
  border-radius: 0;
}
.k-toolbar .k-button,
.k-toolbar .k-split-button,
.k-toolbar .k-button-group,
.k-toolbar .k-widget,
.k-toolbar .k-textbox,
.k-toolbar label,
.k-toolbar .k-separator {
  margin: 0 .2em;
  line-height: 1.72em;
  vertical-align: middle;
}
.k-toolbar .k-split-button {
  padding-left: 0;
}
.k-toolbar .k-split-button .k-button,
.k-toolbar .k-button-group .k-group-start {
  margin: 0;
}
.k-toolbar .k-split-button .k-split-button-arrow {
  margin: 0 0 0 -1px;
}
.k-toolbar .k-overflow-anchor {
  border-width: 0 0 0 1px;
  border-style: solid;
  height: 3em;
  width: 3em;
  line-height: inherit;
  padding: 0 .5em;
  margin: 0;
  position: relative;
  float: right;
  border-radius: 0;
}
.k-overflow-container .k-item {
  float: none;
  border: 0;
}
.k-overflow-container .k-separator {
  border-width: 0 0 1px;
  border-style: solid;
  height: 1px;
  line-height: 0;
  font-size: 0;
  padding: 0;
}
.k-overflow-container .k-overflow-button,
.k-split-container .k-button {
  text-align: left;
  display: block;
  background: none;
  border-color: transparent;
  white-space: nowrap;
}
.k-split-container {
  margin-top: -1px;
}
.k-overflow-container .k-button-group {
  padding: 0;
}
.k-overflow-container .k-button-group > li {
  display: block;
}
.k-overflow-container .k-overflow-group {
  border-width: 1px 0;
  border-style: solid;
  border-radius: 0;
  padding: 2px 0;
  margin: 1px 0;
}
.k-overflow-container .k-overflow-hidden {
  display: none;
}
.k-overflow-container .k-toolbar-first-visible,
.k-overflow-container .k-overflow-group + .k-overflow-group,
.k-overflow-container .k-separator + .k-overflow-group {
  border-top: 0;
  margin-top: 0;
  padding-top: 1px;
}
.k-overflow-container .k-overflow-group + .k-separator {
  display: none;
}
.k-overflow-container .k-toolbar-last-visible {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 1px;
}
/* Splitter */
.k-splitter {
  position: relative;
  height: 300px;
}
.k-pane > .k-splitter {
  border-width: 0;
  overflow: hidden;
}
.k-splitter .k-pane {
  overflow: hidden;
}
.k-splitter .k-scrollable {
  overflow: auto;
}
.k-splitter .k-pane-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
}
.k-ghost-splitbar,
.k-splitbar {
  position: absolute;
  border-style: solid;
  font-size: 0;
  outline: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.k-splitter .k-ghost-splitbar-horizontal,
.k-splitter .k-splitbar-horizontal {
  top: 0;
  width: 5px;
  border-width: 0 1px;
  background-repeat: repeat-y;
}
.k-ghost-splitbar-vertical,
.k-splitbar-vertical {
  left: 0;
  height: 5px;
  border-width: 1px 0;
  background-repeat: repeat-x;
}
.k-splitbar-draggable-horizontal {
  cursor: w-resize;
}
.k-splitbar-draggable-vertical {
  cursor: n-resize;
}
.k-splitbar .k-resize-handle {
  display: none;
}
.k-restricted-size-horizontal,
.k-restricted-size-vertical {
  background-color: #f00;
}
.k-splitbar-horizontal .k-icon {
  position: absolute;
  top: 50%;
  width: 5px;
  height: 20px;
  margin-top: -10px;
}
.k-collapse-prev,
.k-collapse-next,
.k-expand-prev,
.k-expand-next {
  cursor: pointer;
}
.k-splitbar-horizontal .k-collapse-prev {
  margin-top: -31px;
}
.k-splitbar-horizontal .k-collapse-next {
  margin-top: 11px;
}
.k-splitbar-static-horizontal {
  width: 1px;
}
.k-splitbar-static-vertical {
  height: 1px;
}
.k-splitbar-vertical .k-icon {
  position: absolute;
  left: 50%;
  width: 20px;
  height: 5px;
  margin-left: -10px;
}
.k-splitbar-vertical .k-collapse-prev {
  margin-left: -31px;
}
.k-splitbar-vertical .k-collapse-next {
  margin-left: 11px;
}
.k-splitbar-draggable-vertical .k-resize-handle,
.k-splitbar-draggable-horizontal .k-resize-handle {
  display: inline-block;
}
.k-splitbar-horizontal .k-resize-handle {
  background-position: -165px -6px;
}
.k-splitbar-horizontal-hover > .k-resize-handle {
  background-position: -181px -6px;
}
.k-splitbar-horizontal .k-collapse-prev,
.k-splitbar-horizontal .k-expand-next {
  background-position: -6px -174px;
}
.k-splitbar-horizontal-hover > .k-collapse-prev,
.k-splitbar-horizontal-hover > .k-expand-next {
  background-position: -22px -174px;
}
.k-splitbar-horizontal .k-collapse-next,
.k-splitbar-horizontal .k-expand-prev {
  background-position: -5px -142px;
}
.k-splitbar-horizontal-hover > .k-collapse-next,
.k-splitbar-horizontal-hover > .k-expand-prev {
  background-position: -21px -142px;
}
.k-splitbar-vertical .k-resize-handle {
  background-position: -38px -309px;
}
.k-splitbar-vertical-hover > .k-resize-handle {
  background-position: -70px -309px;
}
.k-splitbar-vertical .k-collapse-prev,
.k-splitbar-vertical .k-expand-next {
  background-position: 2px -134px;
}
.k-splitbar-vertical-hover > .k-collapse-prev,
.k-splitbar-vertical-hover > .k-expand-next {
  background-position: -14px -134px;
}
.k-splitbar-vertical .k-collapse-next,
.k-splitbar-vertical .k-expand-prev {
  background-position: 2px -165px;
}
.k-splitbar-vertical-hover > .k-collapse-next,
.k-splitbar-vertical-hover > .k-expand-prev {
  background-position: -14px -165px;
}
.k-splitter-resizing {
  overflow: hidden;
}
/* Virtual List */
.k-virtual-wrap {
  position: relative;
}
.k-virtual-wrap .k-list.k-virtual-list {
  height: auto;
}
.k-virtual-content {
  overflow-y: scroll;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  position: relative;
}
.k-virtual-list > .k-virtual-content {
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.k-virtual-option-label {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.k-virtual-wrap > .k-virtual-header {
  text-align: right;
}
.k-popup .k-item.k-first {
  position: relative;
}
.k-virtual-content > .k-virtual-list > .k-virtual-item {
  position: absolute;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
}
.k-popup .k-list .k-item > .k-group,
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  text-transform: uppercase;
  font-size: .857em;
}
.k-popup .k-list .k-item > .k-group {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 .5em;
  line-height: 1.8;
}
.k-popup .k-state-hover > .k-group {
  right: -1px;
}
.k-virtual-item.k-first,
.k-group-header + .k-list > .k-item.k-first,
.k-static-header + .k-list > .k-item.k-first {
  border-top-style: solid;
  border-top-width: 1px;
  padding-top: 0;
}
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  text-align: right;
}
/* animation classes */
.k-fx-end .k-fx-next,
.k-fx-end .k-fx-current {
  -webkit-transition: all 350ms ease-out;
          transition: all 350ms ease-out;
}
.k-fx {
  position: relative;
}
.k-fx .k-fx-current {
  z-index: 0;
}
.k-fx .k-fx-next {
  z-index: 1;
}
.k-fx-hidden,
.k-fx-hidden * {
  visibility: hidden !important;
}
.k-fx-reverse .k-fx-current {
  z-index: 1;
}
.k-fx-reverse .k-fx-next {
  z-index: 0;
}
/* Zoom */
.k-fx-zoom.k-fx-start .k-fx-next {
  -webkit-transform: scale(0) !important;
      -ms-transform: scale(0) !important;
          transform: scale(0) !important;
}
.k-fx-zoom.k-fx-end .k-fx-next {
  -webkit-transform: scale(1) !important;
      -ms-transform: scale(1) !important;
          transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next,
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: scale(1) !important;
      -ms-transform: scale(1) !important;
          transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: scale(1) !important;
      -ms-transform: scale(1) !important;
          transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: scale(0) !important;
      -ms-transform: scale(0) !important;
          transform: scale(0) !important;
}
/* Fade */
.k-fx-fade.k-fx-start .k-fx-next {
  will-change: opacity;
  opacity: 0;
}
.k-fx-fade.k-fx-end .k-fx-next {
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: opacity;
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
  opacity: 0;
}
/* Slide */
.k-fx-slide {
  /* left */
  /* left reverse */
  /* right */
}
.k-fx-slide.k-fx-end .k-fx-next .km-content,
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer,
.k-fx-slide.k-fx-end .k-fx-current .km-content,
.k-fx-slide.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-end .k-fx-current .km-footer {
  -webkit-transition: all 350ms ease-out;
          transition: all 350ms ease-out;
}
.k-fx-slide.k-fx-start .k-fx-next .km-content {
  will-change: transform;
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-slide.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-start .k-fx-next .km-footer {
  will-change: opacity;
  opacity: 0;
}
.k-fx-slide.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  will-change: transform;
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer {
  will-change: opacity;
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer {
  opacity: 0;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-right {
  /* right reverse */
}
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  -webkit-transform: translatex(0%);
      -ms-transform: translatex(0%);
          transform: translatex(0%);
}
/* Tile */
.k-fx-tile {
  /* left */
  /* left reverse */
  /* right */
}
.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-right {
  /* right reverse */
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0%);
      -ms-transform: translatex(0%);
          transform: translatex(0%);
}
/* Tile */
.k-fx-tile {
  /* left */
  /* left reverse */
  /* right */
}
.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-right {
  /* right reverse */
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  -webkit-transform: translatex(0);
      -ms-transform: translatex(0);
          transform: translatex(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  -webkit-transform: translatex(0%);
      -ms-transform: translatex(0%);
          transform: translatex(0%);
}
/* Overlay */
.k-fx.k-fx-overlay.k-fx-start .k-fx-next,
.k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next {
  will-change: transform;
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
  -webkit-transform: translatey(100%);
      -ms-transform: translatey(100%);
          transform: translatey(100%);
}
.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
  -webkit-transform: translatey(-100%);
      -ms-transform: translatey(-100%);
          transform: translatey(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current,
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
  -webkit-transform: translatex(100%);
      -ms-transform: translatex(100%);
          transform: translatex(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
  -webkit-transform: translatex(-100%);
      -ms-transform: translatex(-100%);
          transform: translatex(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
  -webkit-transform: translatey(100%);
      -ms-transform: translatey(100%);
          transform: translatey(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
  -webkit-transform: translatey(-100%);
      -ms-transform: translatey(-100%);
          transform: translatey(-100%);
}
.k-widget.k-tabstrip {
  background-color: transparent;
  background-image: none;
  border-style: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-tabstrip .k-tabstrip-items {
  padding: 0;
}
.k-tabstrip > .k-content,
.k-panelbar .k-tabstrip > .k-content {
  margin: 0;
}
.k-slider-track {
  border-width: 1px;
  border-style: solid;
}
.k-slider-horizontal .k-slider-track,
.k-slider-horizontal .k-slider-selection {
  margin-top: -5px;
}
.k-slider-vertical .k-slider-track,
.k-slider-vertical .k-slider-selection {
  margin-left: -5px;
}
.k-textbox > input,
.k-autocomplete .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input {
  padding: 0.167em 0;
}
.k-numerictextbox .k-link {
  height: 1.15em;
}
.k-numerictextbox .k-select .k-link span.k-i-arrow-n {
  background-position: 0 -1px;
}
.k-numerictextbox .k-select .k-link span.k-i-arrow-s {
  background-position: 0 -36px;
}
.k-webkit .k-rtl .k-widget .k-dropdown-wrap .k-input,
.k-webkit .k-rtl .k-widget .k-numeric-wrap .k-input {
  margin-right: 0;
}
.k-pager-wrap,
.k-pager-numbers .k-link {
  line-height: 2.3em;
}
.k-pager-wrap > .k-link {
  height: 2.3em;
  line-height: 2.3em;
}
.k-pager-wrap > .k-link,
.k-pager-numbers .k-link,
.k-pager-numbers .k-state-selected {
  min-width: 2.3em;
}
.k-pager-sizes .k-dropdown-wrap .k-input {
  line-height: 2em;
}
.k-toolbar .k-button-group .k-button {
  margin: 0;
}
.k-checkbox-label:before,
.k-checkbox-label:after,
.k-radio-label:before,
.k-radio-label:after {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
